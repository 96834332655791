import styled from 'styled-components';
import { media } from '../../helper';

export const Container = styled.section`

`;

export const Banner = styled.article`
  min-height: 560px;
  background-color: #5c3d20;
  padding: 0 24px 40px;
  background-repeat: no-repeat;
  background-image: url(${props => props.background});
  background-size: cover;
  position: relative;
  background-position: center;

  & .layer {
    background-color: #000000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  & .over-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  & .content {
    max-width: 1328px;
    margin: 0 auto;
    padding: 0 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & .logo-section {
    padding: 20px 12px;
    background-color: #ffffff;
    border-radius: 0 0 4px 4px;
  }

  & .logo-section img {
    height: 40px;
  }

  & nav {
    display: flex;
    align-items: center;
  }

  & nav a {
    text-shadow: 0 2px 4px 0 rgba(164, 164, 164, 0.5);
    color: #FFFFFF;
    font-size: 16px;
    transition: opacity 0.25s;
  }

  & nav a:hover {
    opacity: 0.9;
  }

  & nav a:not(:first-child) {
    margin-left: 48px;
  }

  & .language {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    outline: none;
    border: none;
    cursor: pointer;
    text-align: center;
    margin-left: 24px;
    flex-shrink: 0;
    text-transform: uppercase;
  }

  & .reservar-button {
    background: #D8B93C;
    padding: 12px 40px;
    border-radius: 4px;
  }

  & .reservation {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  & .reservation > div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
  }

  & .reservation .space-between input {
    max-width: 49%;
  }

  & .top-section svg {
    display: none;
    color: #ffffff;
  }

  ${media.medium} {
    & nav {
      display: none;
    }

    & .top-section {
      padding: 0 16px;
    }

    & .top-section svg {
      display: block;
    }

    & .reservation {
      justify-content: center;
    }
  }
`;

export const ReservationForm = styled.div`
  max-width: 100%;
  padding: 24px 32px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(94, 94, 94, 0.5);
  width: 360px;
  display: flex;
  flex-direction: column;

  & h1 {
    font-size: 18px;
    margin-bottom: 16px;
  }

  & h3 {
    font-size: 14px;
    margin-bottom: 24px;
  }

  & input {
    margin-bottom: 16px;
  }

  & a {
    font-size: 16px;
    color: #ffffff;
    width: 100%;
    padding: 12px;
    background-color: #060B5B;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    text-align: center;
  }
`;

export const Hotels = styled.article`
  max-width: 1328px;
  margin: 0 auto;
  padding: 72px 24px 40px;

  & h2 {
    color: #060B5B;
    font-size: 40px;
    margin-bottom: 24px;
    text-align: center;
  }

  & .description {
    text-align: justify;
    margin-bottom: 40px;
    font-size: 24px;
  }

  & .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  & .list > * {
    margin-bottom: 40px;
    width: calc((100% / 3) - 16px);
  }

  ${media.small} {
    & h2 {
      font-size: 32px;
    }

    & .description {
      font-size: 18px;
    }

    & .list {
      flex-direction: column;
    }

    & .list > * {
      width: 100%;
      margin-bottom: 24px;
    }
  }
`;

export const CallToAction = styled.article`
  height: 320px;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: bottom;
  position: relative;

  & .layer {
    background-color: #000000;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  & .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding-top: 98px;
    text-align: center;
    color: #ffffff;
  }

  & .content h2 {
    text-shadow: 0 2px 4px 0 rgba(246, 243, 243, 0.5);
    font-size: 40px;
    margin-bottom: 16px;
  }

  & .content h4 {
    text-shadow: 0 2px 4px 0 rgba(246, 243, 243, 0.5);
    font-size: 24px;
    margin-bottom: 24px;
  }

  & .content .promotions {
    padding: 14px 16px;
    background-color: #D8B93C;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 60px;
  }

  & .content .restrictions {
    font-size: 12px;
  }

  ${media.small} {
    & .content h2 {
      font-size: 32px;
    }

    & .content h4 {
      font-size: 18px;
    }
  }
`;

export const Location = styled.article`
  padding: 80px 0;
  text-align: center;

  & h2 {
    margin-bottom: 40px;
    color: #060B5B;
    font-size: 40px;
  }

  & .description {
    font-size: 24px;
    margin-bottom: 40px;
  }

  ${media.small} {
    padding: 40px 0;

    & h2 {
      margin-bottom: 32px;
      font-size: 32px;
    }

    & .description {
      font-size: 18px;
      margin-bottom: 32px;
    }
  }
`;

export const DrawerContainer = styled.div`
  min-width: 280px;
  padding: 32px 24px;

  & > img {
    width: 160px;
    margin-bottom: 40px;
  }

  & nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & nav a {
    margin-bottom: 32px;
    color: #000000;
  }

  & .reservar-button {
    background: #D8B93C;
    padding: 12px 40px;
    border-radius: 4px;
  }
`;

export const CommentsBanner = styled.article`
  height: 370px;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 40px;

  & .layer {
    background-color: #000000;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  & .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding-top: 98px;
    text-align: center;
    color: #ffffff;
  }

  & .content h2 {
    text-shadow: 0 2px 4px 0 rgba(246, 243, 243, 0.5);
    font-size: 40px;
    margin-bottom: 16px;
  }

  & .content h4 {
    text-shadow: 0 2px 4px 0 rgba(246, 243, 243, 0.5);
    font-size: 24px;
    margin-bottom: 24px;
  }

  & .content .promotions {
    padding: 14px 16px;
    background-color: #D8B93C;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 60px;
  }

  & .content .restrictions {
    font-size: 12px;
  }

  ${media.small} {
    & .content h2 {
      font-size: 32px;
    }

    & .content h4 {
      font-size: 18px;
    }
  }
`;

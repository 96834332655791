import React from 'react';
import CallIcon from '@material-ui/icons/Call';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import { Container } from './styles';
import messages from '../../getMessages';
import WhatsappIcon from './whatsapp.svg';
import { Fab } from '@material-ui/core';



export default function Input({ hotel, isButton, onHotelSelected, data }) {
  const [image, name, location, phones, link, whatsAppPhone] = (() => {
    switch (hotel) {
      case 'la-paz':
        return [
          get((data || []).find(i => i.place === 'la-paz'), 'thumbnail', ''),
          'Hotel Marioris La Paz',
          'Carretera Transpeninsular al Norte Km. 8.6 S/N, Ejido Chametla. C.P. 23205.',
          '(612) 124 65 00',
          '/la-paz',
          '526121088418',
        ];
      case 'guadalajara':
        return [
          get((data || []).find(i => i.place === 'guadalajara'), 'thumbnail', ''),
          'Hotel Maioris Guadalajara',
          'Anillo Periférico Sur No. 7200, Fracc. Industrial El Camino, C.P. 45601.',
          '(333) 133 01 38',
          '/guadalajara',
          '523317691477',
        ];
      case 'el-diez':
        return [
          get((data || []).find(i => i.place === 'el-diez'), 'thumbnail', ''),
          'Hotel Maioris El Diez Culiacán',
          'Carretera Culiacán-Eldorado Km 4.5 #7220, Culiacán, Sinaloa, México. C.P. 80155.',
          '(667) 760 50 14',
          '/el-diez',
          '526675031584',
        ];
      case 'navolato':
        return [
          get((data || []).find(i => i.place === 'navolato'), 'thumbnail', ''),
          'Hotel Maioris Navolato',
          'Carretera Culiacán-Navolato Km 12.5, Culiacán, Sinaloa, México. C.P. 80374.',
          '(667) 503 96 77',
          '/navolato',
          '526675039677',
        ];
      case 'kumate':
        return [
          get((data || []).find(i => i.place === 'kumate'), 'thumbnail', ''),
          'Hotel Maioris Jesus Kumate Culiacán',
          'Boulevard Dr. Jesús Kumate Rodríguez #2698 Sur, Col. Ejido Barrancos',
          '(667) 727 36 36',
          '/kumate',
          '526673032104',
        ];
      case 'bellavista':
        return [
          get((data || []).find(i => i.place === 'bellavista'), 'thumbnail', ''),
          'Hotel Maioris Bellavista',
          'Carretera Culiacán - Culiacancito #5750 Bellavista, 80300 Culiacán Rosales, Sin.',
          '(667) 502 70 71',
          '/bellavista',
          '526675027071',
        ];
      default:

    }
  })();
console.log('isButton', isButton);
  if (isButton) {
    return (
      <Container image={image} onClick={onHotelSelected} isButton>
        <div className="bottom">
          <h4>{name}</h4>
          <div className="location">{location}</div>
          <div className="phones">
            <CallIcon />{phones}
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Link to={link}>
      <Container image={image}>
        <div className="bottom">
          <h4>{name}</h4>
          <div className="location">{location}</div>
          <div className="phones">
            <CallIcon />{phones}
          </div>
        </div>
        <div className="see-more">{messages.seeMore}</div>
        <a className="whatsapp-button-card" href={`https://api.whatsapp.com/send?phone=${whatsAppPhone}`} onClick= {(e) => e.stopPropagation()}>
          <Fab size="small">
            <img src={WhatsappIcon} alt="" />
          </Fab>
        </a>
      </Container>
    </Link>
  );
}

/**
 * OrderItem
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import Numeral from 'numeral';

import BedIcon from '@material-ui/icons/SingleBed';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import Button from '@material-ui/core/Button';

import { Container } from './styles';
import messages from '../../getMessages';

export default function OrderItem({ room, onDelete, disabled }) {
  const withPromo = room.price && room.price < room.basePrice;
  return (
    <Container>
      <div>
        <div className="room-name">{room.name}</div>
        <div className="room-detail">
          {`${room.adults} ${messages.roomsReservation.adults}, ${room.children} ${messages.roomsReservation.children} ${messages.max}`}
        </div>
        {!disabled && (
          <Button variant="text" color="secondary" onClick={onDelete}>
            {messages.delete}
          </Button>
        )}
      </div>
      <div className="price-detail">
        <div className="price">{Numeral(room.price).format('$ 0,0.00')}</div>
        {withPromo && <div className="label-offer">{messages.offer}</div>}
      </div>
    </Container>
  );
}

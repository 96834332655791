import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import {
  Container,
  Item,
} from './styles';

import messages from '../../getMessages';
import { Fab } from '@material-ui/core';


const items = [
  {
    key: 'la-paz',
    name: 'Hotel Maioris La Paz',
    phones: '(612) 124 65 00 – 124 66 00',
    email: 'lapaz@hotelesmaioris.com | ventaslapaz@hotelesmaioris.com',
    location: 'Carretera Transpeninsular al Norte Km. 8.6 S/N, Ejido Chametla. La Paz, Baja California Sur, México. C.P. 23205',
    image: '/images/paz.jpg',
    link: '/la-paz',
    whatsAppPhone: '526121088418',
  },
  {
    key: 'guadalajara',
    name: 'Hotel Maioris Guadalajara',
    phones: ' (333) 133 01 38 – 135 33 49',
    email: 'guadalajara@hotelesmaioris.com | ventasgdl@hotelesmaioris.com',
    location: 'Anillo Periférico Sur No. 7200, Fracc. Industrial El Camino, Guadalajara, Jalisco, México. C.P. 45601',
    image: '/images/guadalajara.jpg',
    link: '/guadalajara',
    whatsAppPhone: '523317691477',
  },
  {
    key: 'kumate',
    name: 'Hotel Maioris Jesús Kumate Culiacán',
    phones: '(667) 727 36 36 – 727 37 37',
    email: 'kumate@hotelesmaioris.com',
    location: 'Boulevard Dr. Jesús Kumate Rodríguez #2698 Sur Col. Ejido Barrancos, Culiacán, Sinaloa, México. C.P. 80111',
    image: '/images/kumate.jpg',
    link: '/kumate',
    whatsAppPhone: '526673032104',
  },
  {
    key: 'el-diez',
    name: 'Hotel Maioris El Diez Culiacán',
    phones: '(667) 760 50 14',
    email: 'eldiez@hotelesmaioris.com',
    location: 'Carretera Culiacán - Eldorado Km 4.5 #7220 Culiacán, Sinaloa, México. C.P. 80155',
    image: '/images/diez.jpg',
    link: '/el-diez',
    whatsAppPhone: '526675031584',
  },
  {
    key: 'navolato',
    name: 'Hotel Maioris Navolato',
    phones: '(667) 503 96 77',
    email: 'navolato@hotelesmaioris.com',
    location: 'Carretera Culiacán - Navolato Km 12.5, Navolato, Sinaloa, México. C.P. 80374',
    image: '/images/navolato.jpg',
    link: '/navolato',
    whatsAppPhone: '526675039677',
  },
  {
    key: 'bellavista',
    name: 'Hotel Maioris Bellavista Culiacán',
    phones: '(667) 502 70 71',
    email: 'bellavista@hotelesmaioris.com',
    location: 'Carretera Culiacán - Culiacancito #5750 Col. Bellavista, Culiacán, Sinaloa, México. C.P. 80300',
    image: '/images/bellavista.jpg',
    link: '/bellavista',
    whatsAppPhone: '526675027071',
  },
];

export default function Contact({ children, data }) {
  return (
    <Container>
      <Header />
      <h1>{messages.contact}</h1>
      <div className="description">
        {messages.contactDesc}
      </div>
      <div className="restaurants-list">
        {items.map(item => (
          <Item key={item.name}>
            <Link to={item.link}>
              <img
                src={get((get(data, 'places') || []).find(p => p.place === item.key), 'thumbnail')}
                alt={item.name}
              />
            </Link>
            <div className="item-content">
              <h4>{item.name}</h4>
              <div className="item-detail">
                <img src="/iconos/phone.svg" alt="" />
                {item.phones}
              </div>
              <div className="item-detail">
                <img src="/iconos/mail.svg" alt="" />
                {item.email}
              </div>
              <div className="item-detail">
                <img src="/iconos/location.svg" alt="" />
                {item.location}
              </div>
              <a className="item-whatsapp" href={`https://api.whatsapp.com/send?phone=${item.whatsAppPhone}`}>
                  
                    <img className="item-whatsapp" src="/iconos/whatsapp.svg" alt="" />
                    {"Contacto por Whatsapp"}
                  
              </a>
            </div>
          </Item>
        ))}
      </div>
      <Footer />
    </Container>
  );
}

import styled from 'styled-components';
import { media } from '../../helper';

export const Container = styled.section`
  padding-top: 80px;

  & .content-reservation {
    max-width: 1328px;
    margin: 0 auto;
    padding: 64px 24px;
  }

  & h1 {
    color: #060B5B;
    font-size: 40px;
    margin-bottom: 24px;
  }

  & .brief {
    margin-bottom: 32px;
    font-size: 21px;
  }

  & .form {
    max-width: 470px;
    margin-bottom: 80px;
  }

  & .form > * {
    margin-bottom: 24px;
  }

  & .form > label {
    margin-bottom: 0;
  }

  & .small-label {
    margin-bottom: 8px;
    font-size: 12px;
  }

  & .form .send {
    padding: 16px;
    width: 100%;
    background-color: #060B5B;
    border-radius: 4px;
    color: #ffffff;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    margin-top: 16px;
  }

  & .form .send:disabled {
    background-color: #adadad;
    cursor: default;
  }

  & .form textarea {
    margin-bottom: 16px;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    width: 100%;
    padding: 16px;
    outline: none;
    font-size: 14px;
    background-color: #ffffff;
    min-height: 200px;
    max-width: 100%;
    min-width: 100%;
  }

  & .content-reservation h2 {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #DBD8D8;
  }

  & .inputs-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  & .inputs-list > * {
    width: calc((100% / 3) - (48px / 3));
    max-height: 42px;
  }

  & .inputs-list > *:nth-child(-n + 6) {
    margin-bottom: 24px;
  }

  & .inputs-list .couple-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .inputs-list .couple-inputs > * {
    width: 49%;
  }

  & .brief {
     display: flex;
     align-items: center;
     justify-content: space-between;
  }

  & .brief .cost {
    font-weight: 500;
    color: #060B5B;
    font-size: 24px;
  }

  & .brief button {
    border: none;
    outline: none;
    width: calc((100% / 3) - (48px / 3));
    padding: 11px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    background-color: #060B5B;
    border-radius: 4px;
  }

  & .card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  & .card-list > * {
    margin-bottom: 40px;
    width: calc((100% / 3) - 16px);
  }

  ${media.small} {
    & .content-reservation {
      padding: 24px;
    }

    & .inputs-list {
      flex-direction: column;
    }

    & .inputs-list > * {
      width: 100%;
      margin-bottom: 24px;
    }

    & .brief {
      flex-direction: column;
    }

    & .brief button {
      width: 100%;
      margin-top: 24px;
    }

    & .card-list {
      flex-direction: column;
    }

    & .card-list > * {
      width: 100%;
    }
  }
`;

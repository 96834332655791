import styled from 'styled-components';
import { media } from '../../helper';

export const Container = styled.section`
  & .detail {
    max-width: 1328px;
    padding: 0 24px;
    margin: 0 auto;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
  }

  & .rest-content {
    max-width: 1328px;
    padding: 0 24px;
    margin: 0 auto;
  }

  & .detail .left-section {
    width: calc(100% - 360px - 80px);
  }

  & .detail .left-section h1 {
    margin-bottom: 16px;
    color: #232323;
    font-size: 24px;
    font-weight: 500;
  }

  & .detail .left-section h2 {
    margin-bottom: 24px;
    color: #232323;
    font-size: 24px;
    font-weight: 500;
  }

  & .detail .left-section p {
    color: #232323;
    font-size: 18px;
    margin-bottom: 16px;
  }

  & .rooms {
    display: flex;
    flex-wrap: wrap;
  }

  & .rooms > div:not(:last-child) {
    margin-right: 16px;
  }

  & .rooms > div {
    margin-bottom: 16px;
  }

  & .services {
    display: flex;
  }

  & .services > div:first-child {
    margin-right: 40px;
  }

  & .whatsapp-button {
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 9;
  }
  
  & .whatsapp-button:hover {
    z-index: 11;
  }

  & .whatsapp-button button {
    background-color: #37d043;
  }

  & .whatsapp-button img {
    width: 28px;
  }

  & .whatsapp-button svg {
    color: #ffffff;
  }

  & .whatsapp-button button:hover {
    background-color: #37d043;
  }

  & .see-more-button {
    background: #ffffff;
    border: 1px solid #060B5B;
    border-radius: 4px;
    cursor: pointer;
    color: #05055D;
    font-size: 12px;
    padding: 8px 36px;
    outline: none;
    transition: opacity 0.25s;
  }

  & .see-more-button:hover {
    opacity: 0.7;
  }

  & .call-button {
    position: fixed;
    bottom: 24px;
    right: 75px;
    z-index: 10;
  }

  & .call-button button {
    background-color: #060B5B;
  }

  & .call-button svg {
    color: #ffffff;
  }

  & .call-button button:hover {
    background-color: #060B5B;
  }

  ${media.small} {
    & .detail {
      margin-top: 24px;
      flex-direction: column;
    }

    & .detail .left-section {
      width: 100%;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dddddd;
  margin: 24px 0;
`;

export const ReservationForm = styled.div`
  max-width: 100%;
  padding: 24px 32px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(94, 94, 94, 0.5);
  width: 360px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 110px;

  & h1 {
    font-size: 18px;
    margin-bottom: 16px;
  }

  & h3 {
    font-size: 14px;
    margin-bottom: 24px;
  }

  & input {
    margin-bottom: 16px;
  }

  & a {
    font-size: 16px;
    color: #ffffff;
    width: 100%;
    padding: 12px;
    background-color: #060B5B;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    text-align: center;
  }

  ${media.small} {
    position: relative;
    top: 24px;
  }
`;

export const Room = styled.div`
  min-width: 156px;
  padding: 16px;
  color: #232323;
  border: 1px solid #dddddd;
  border-radius: 4px;

  & .name {
    font-size: 12px;
    margin-bottom: 12px;
    font-weight: bold;
  }

  & .beds {
    display: flex;
    margin-bottom: 12px;
  }

  & .beds svg {
    font-size: 18px;
  }

  & .beds svg:not(:last-child) {
    margin-right: 8px;
  }

  & .room-detail {
    font-size: 10px;
  }

  & .room-detail:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Service = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 16px;

  & img {
    margin-right: 8px;
  }
`;

export const Location = styled.div`
  padding: 0 0 24px;
  margin: 0 auto;
  border-bottom: 1px solid #dddddd;

  & h2 {
    margin-bottom: 24px;
    color: #232323;
    font-size: 24px;
    font-weight: 500;
  }
`;

export const Activities = styled.div`
  padding: 24px 0;
  margin: 0 auto;
  border-bottom: 1px solid #dddddd;

  & h2 {
    margin-bottom: 24px;
    color: #232323;
    font-size: 24px;
    font-weight: 500;
  }

  & .description {
    margin-bottom: 24px;
    font-size: 16px;
  }
`;

export const Comments = styled.div`
  padding: 24px 0;
  margin: 0 auto;
  border-bottom: 1px solid #dddddd;

  & h2 {
    margin-bottom: 24px;
    color: #232323;
    font-size: 24px;
    font-weight: 500;
  }

  & .description {
    margin-bottom: 24px;
    font-size: 16px;
  }

  & .comments {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  & .comments > div:not(:last-child) {
    margin-right: 32px;
    margin-bottom: 16px;
  }
`;

export const Avatar = styled.div`
  background-color: #060B5B;
  margin-right: 16px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  flex-shrink: 0;
  line-height: 1;
`;

export const CommentItem = styled.div`
  padding: 16px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  max-width: 240px;

  & .person {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }

  & .person .client-name {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  & .person .stars {
    display: flex;
  }

  & .person .stars img {
    margin-right: 8px;
  }

  & .text {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const MoreHotels = styled.div`
  padding: 24px 0 64px;
  margin: 0 auto;
  border-bottom: 1px solid #dddddd;

  & h2 {
    margin-bottom: 32px;
    color: #232323;
    font-size: 24px;
    font-weight: 500;
  }

  & .hotels {
    display: flex;
  }

  & .hotels > * {
    margin-right: 24px;
    max-width: 340px;
    width: calc((100% / 3) - 16px);
  }

  ${media.small} {
    padding: 24px 0 32px;

    & .hotels {
      flex-direction: column;
    }

    & .hotels > * {
      margin-right: 0;
      margin-bottom: 24px;
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const MoreServices = styled.div`
  padding: 32px 24px;
  background: #ffffff;
  width: ${props => props.width || '400px'};
  max-width: 100%;
  position: relative;
  white-space: pre-wrap;
  text-align: justify;
  hyphens: auto;

  & h2 {
    margin-bottom: 32px;
  }

  & .item {
    padding: 16px 0;
    border-top: 1px solid #f3f3f3;
  }

  & .close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

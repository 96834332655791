import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { get } from 'lodash';

import Header from '../../components/Header';
import Gallery from '../../components/Gallery';
import Footer from '../../components/Footer';
import messages from '../../getMessages';

import {
  Container,
  Banner,
} from './styles';

export default function Covid({ children, data }) {
  const [galleryOpen, setGalleryOpen] = useState(false);

  const images = JSON.parse(get(data, 'landingConfig.gallery') || '[]');

  return (
    <Container>
      <Header />
      <div className="content-protocols">
        <h1>{messages.covid.protocols}</h1>
        <div className="description">
          {messages.covid.description}
        </div>
        <div className="list-protocol">
          <div className="item">
            <img src="/covid/clean_hands.svg" alt="" />
            <span>{messages.covid.hands}</span>
          </div>
          <div className="item">
            <img src="/covid/masks.svg" alt="" />
            <span>{messages.covid.mask}</span>
          </div>
          <div className="item">
            <img src="/covid/social_distance.svg" alt="" />
            <span>{messages.covid.distance}</span>
          </div>
          <div className="item">
            <img src="/covid/sanitizer.svg" alt="" />
            <span>{messages.covid.areas}</span>
          </div>
          <div className="item">
            <img src="/covid/filter_alt.svg" alt="" />
            <span>{messages.covid.checkin}</span>
          </div>
          <div className="item">
            <img src="/covid/luggage.svg" alt="" />
            <span>{messages.covid.luggage}</span>
          </div>
          <div className="item">
            <img src="/covid/shoes.svg" alt="" />
            <span>{messages.covid.shoes}</span>
          </div>
        </div>
      </div>
      <Banner background={get(data, 'landingConfig.covid', '')}>
        <h2>COVID-19</h2>
        <div className="secondary-label">
          {messages.covid.measures}
        </div>
        <button onClick={() => setGalleryOpen(true)}>
          {messages.watch}
        </button>
      </Banner>
      <Gallery
        images={images}
        hidden
        open={galleryOpen}
        onClose={() => setGalleryOpen(false)}
      />
      <Footer />
    </Container>
  );
}

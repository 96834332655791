import styled from 'styled-components';
import { media } from '../../helper';

export const Container = styled.section`
  padding-top: 80px;

  & .content-protocols {
    padding: 64px 24px 80px;
    margin: 0 auto;
    max-width: 1328px;
  }

  & h1 {
    font-size: 40px;
    color: #060B5B;
    margin-bottom: 24px;
  }

  & .description {
    font-size: 21px;
    margin-bottom: 32px;
  }

  & .list-protocol {
    padding: 32px;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }

  & .list-protocol .item {
    display: flex;
    align-items: center;
    font-size: 21px;
  }

  & .list-protocol .item img {
    margin-right: 16px;
  }

  & .list-protocol .item:not(:last-child) {
    margin-bottom: 32px;
  }

  ${media.small} {
    & .content-protocols {
      padding: 32px 24px;
    }

    & h1 {
      font-size: 32px;
    }

    & .description {
      font-size: 18px;
    }

    & .list-protocol {
      padding: 24px;
    }

    & .list-protocol .item {
      font-size: 16px;
    }
  }
`;

export const Banner = styled.article`
  height: 372px;
  width: 100%;
  padding-top: 90px;
  text-align: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.background});
  background-size: cover;

  & h2 {
    font-size: 40px;
    color: #FFFFFF;
    text-shadow: 0 2px 4px rgba(246,243,243,0.50);
    margin-bottom: 16px;
  }

  & .secondary-label {
    margin-bottom: 32px;
    font-size: 24px;
    color: #FFFFFF;
    text-shadow: 0 2px 4px rgba(246,243,243,0.50);
  }

  & button {
    min-width: 156px;
    padding: 14px 16px;
    background-color: #D8B93C;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 60px;
  }

  ${media.small} {
    height: 300px;

    & h2 {
      font-size: 32px;
    }

    & .secondary-label {
      font-size: 18px;
    }
  }
`;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { times, get } from "lodash";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import Axios from "axios";

import Input from "../../components/Input";
import Card from "../../components/Card";
import Header from "../../components/Header";
import Gallery from "../../components/Gallery";
import Footer from "../../components/Footer";
import BedIcon from "@material-ui/icons/SingleBed";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CallIcon from "@material-ui/icons/Call";
import { Fab } from "@material-ui/core";

import messages from "../../getMessages";
import WhatsappIcon from "./whatsapp.svg";
import { BASE_URL } from "../../helper";

import {
  Container,
  ReservationForm,
  Divider,
  Room,
  Service,
  Location,
  Activities,
  Comments,
  CommentItem,
  MoreHotels,
  MoreServices,
  Avatar,
} from "./styles";

const containerStyle = {
  width: "100%",
  height: "460px",
  maxHeight: "80vh",
};

const scaledSize = { width: 40, height: 40 };
const anchor = { x: 17, y: 46 };

const initialBody = {
  llegada: "",
  salida: "",
  adultos: "",
  ninios: "",
  codigo: "",
};

const curr = new Date();
curr.setDate(curr.getDate() + 3);
const defaultDate = curr.toISOString().substr(0, 10);

export default function HotelDetail({ children, hotel, data }) {
  const isResponsive = useMediaQuery(
    "@media only screen and (max-width: 992px)"
  );

  const [moreServicesOpen, setMoreServicesOpen] = useState(false);
  const [whatToDoOpen, setWhatToDoOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [body, setBody] = useState({ ...initialBody });

  const hotelDetails = (get(data, "places") || []).find(
    (i) => i.place === hotel
  );

  useEffect(() => {
    if (isResponsive) {
      setBody({
        ...initialBody,
        llegada: defaultDate,
        salida: defaultDate,
      });
    }
  }, [isResponsive]);

  useEffect(() => {
    fetchComments();
    fetchRooms();
  }, []);

  const fetchComments = async () => {
    try {
      const response = await Axios.get(`${BASE_URL}/comments/${hotel}`);

      setComments(
        (get(response, "data.data", false) || []).map((item) => ({
          ...item,
          comment: item.text,
        }))
      );
    } catch (e) {
      console.error(e);
    }
  };

  const getDetails = (item) => {
    const details = [];

    if (item.adults) details.push(`${item.adults} ${messages.rooms.adults}`);
    if (item.children)
      details.push(`${item.children} ${messages.rooms.children}`);
    if (item.kingSize)
      details.push(`${item.kingSize} ${messages.rooms.kingSize}`);
    if (item.double)
      details.push(`${item.double} ${messages.rooms.matrimonialSize}`);
    if (item.queenSize)
      details.push(`${item.queenSize} ${messages.rooms.queenSize}`);
    if (item.individual)
      details.push(`${item.individual} ${messages.rooms.individual}`);

    return details;
  };

  const fetchRooms = async () => {
    try {
      const response = await Axios.get(`${BASE_URL}/rooms/${hotel}`);

      const _rooms = get(response, "data.data", false) || [];
      setRooms(
        _rooms.map((item) => ({
          ...item,
          beds: item.double + item.individual + item.kingSize + item.queenSize,
          details: getDetails(item),
        }))
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleSetBody = (key, value) =>
    setBody((_body) => ({
      ..._body,
      ...(key === "llegada" ? { salida: "" } : {}),
      [key]: value,
    }));

  const getUrlBook = () => {
    let link = `/reservar?hotel=${hotel}&`;

    if (body.llegada) {
      link = link.concat(`llegada=${body.llegada}&`);
    }

    if (body.salida) {
      link = link.concat(`salida=${body.salida}&`);
    }

    if (body.adultos) {
      link = link.concat(`adultos=${body.adultos}&`);
    }

    if (body.ninios) {
      link = link.concat(`ninios=${body.ninios}&`);
    }

    if (body.codigo) {
      link = link.concat(`codigo=${body.codigo}&`);
    }

    if (link.charAt(link.length - 1) === "&") {
      link = link.slice(0, -1);
    }

    return link;
  };

  const images = JSON.parse(get(hotelDetails, "gallery") || "[]");

  // const images = (() => {
  //   switch (hotel) {
  //     case 'la-paz':
  //       return [
  //         '/images/paz.jpg',
  //         '/hoteles/la-paz/7.jpg',
  //         '/hoteles/la-paz/6.jpg',
  //         '/hoteles/la-paz/1.jpg',
  //         '/hoteles/la-paz/2.jpg',
  //         '/hoteles/la-paz/4.jpg',
  //         '/hoteles/la-paz/3.jpg',
  //         '/hoteles/la-paz/5.jpg',
  //         '/hoteles/la-paz/8.jpg',
  //       ];
  //     case 'guadalajara':
  //       return [
  //         '/images/guadalajara.jpg',
  //         '/hoteles/guadalajara/18.jpg',
  //         '/hoteles/guadalajara/19.jpg',
  //         '/hoteles/guadalajara/2.jpg',
  //         '/hoteles/guadalajara/16.jpg',
  //         '/hoteles/guadalajara/1.jpg',
  //         '/hoteles/guadalajara/3.jpg',
  //         '/hoteles/guadalajara/4.jpg',
  //         '/hoteles/guadalajara/5.jpg',
  //         '/hoteles/guadalajara/6.jpg',
  //         '/hoteles/guadalajara/7.jpg',
  //         '/hoteles/guadalajara/8.jpg',
  //         '/hoteles/guadalajara/9.jpg',
  //         '/hoteles/guadalajara/10.jpg',
  //         '/hoteles/guadalajara/11.jpg',
  //         '/hoteles/guadalajara/12.jpg',
  //         '/hoteles/guadalajara/13.jpg',
  //         '/hoteles/guadalajara/14.jpg',
  //         '/hoteles/guadalajara/15.jpg',
  //       ];
  //     case 'el-diez':
  //       return [
  //         '/images/diez.jpg',
  //         '/hoteles/el-diez/8.jpg',
  //         '/hoteles/el-diez/1.jpg',
  //         '/hoteles/el-diez/2.jpeg',
  //         '/hoteles/el-diez/3.jpeg',
  //         '/hoteles/el-diez/6.jpg',
  //         '/hoteles/el-diez/7.jpg',
  //         '/hoteles/el-diez/10.jpg',
  //       ];
  //     case 'navolato':
  //       return [
  //         '/images/navolato.jpg',
  //         '/hoteles/navolato/1.jpg',
  //         '/hoteles/navolato/19.jpg',
  //         '/hoteles/navolato/11.jpg',
  //         '/hoteles/navolato/4.jpg',
  //         '/hoteles/navolato/5.jpg',
  //         '/hoteles/navolato/6.jpg',
  //         '/hoteles/navolato/7.jpg',
  //         '/hoteles/navolato/8.jpg',
  //         '/hoteles/navolato/9.jpg',
  //         '/hoteles/navolato/10.jpg',
  //         '/hoteles/navolato/13.jpg',
  //         '/hoteles/navolato/2.jpg',
  //         '/hoteles/navolato/14.jpg',
  //         '/hoteles/navolato/15.jpg',
  //         '/hoteles/navolato/16.jpg',
  //         '/hoteles/navolato/17.jpg',
  //         '/hoteles/navolato/18.jpg',
  //         '/hoteles/navolato/20.jpg',
  //         '/hoteles/navolato/21.jpg',
  //       ];
  //     case 'kumate':
  //       return [
  //         '/images/kumate.jpg',
  //         '/hoteles/kumate/1.jpg',
  //         '/hoteles/kumate/25.jpg',
  //         '/hoteles/kumate/2.jpg',
  //         '/hoteles/kumate/3.jpg',
  //         '/hoteles/kumate/4.jpg',
  //         '/hoteles/kumate/5.jpg',
  //         '/hoteles/kumate/6.jpg',
  //         '/hoteles/kumate/7.jpg',
  //         '/hoteles/kumate/8.jpg',
  //         '/hoteles/kumate/9.jpg',
  //         '/hoteles/kumate/10.jpg',
  //         '/hoteles/kumate/15.jpg',
  //         '/hoteles/kumate/18.jpg',
  //         '/hoteles/kumate/20.jpg',
  //         '/hoteles/kumate/21.jpg',
  //         '/hoteles/kumate/22.jpg',
  //         '/hoteles/kumate/23.jpg',
  //         '/hoteles/kumate/24.jpg',
  //         '/hoteles/kumate/26.jpg',
  //       ];
  //     case 'bellavista':
  //       return [
  //         '/images/bellavista.jpg',
  //         '/hoteles/bellavista/1.jpg',
  //         '/hoteles/bellavista/4.jpg',
  //         '/hoteles/bellavista/6.jpg',
  //         '/hoteles/bellavista/7.jpg',
  //         '/hoteles/bellavista/8.jpg',
  //         '/hoteles/bellavista/10.jpg',
  //         '/hoteles/bellavista/2.jpg',
  //         '/hoteles/bellavista/11.jpg',
  //         '/hoteles/bellavista/12.jpg',
  //         '/hoteles/bellavista/3.jpg',
  //         '/hoteles/bellavista/14.jpg',
  //       ];
  //     default:
  //   }
  // })();

  const [
    name,
    firstParagraph,
    secondParagraph,
    lat,
    lng,
    locationTitle,
    locationText,
    moreHotels,
    restServices,
    whatToDo,
    hotelPhone,
    whatsAppPhone,
  ] = (() => {
    switch (hotel) {
      case "la-paz":
        return [
          "Hotel Maioris La Paz",
          messages.laPaz.brief1,
          messages.laPaz.brief2,
          24.104697,
          -110.3716468,
          messages.laPaz.whatToDo,
          messages.laPaz.shortWhatToDo,
          ["guadalajara", "el-diez", "navolato"],
          [
            messages.services.rooms,
            messages.services.boardRooms,
            messages.services.wheelChair,
            messages.services.fireSafety,
            messages.services.bilingual,
            messages.services.security24,
            messages.services.hygiene,
          ],
          `
${messages.laPaz.fullDescription[0]}

${messages.laPaz.fullDescription[1]}

${messages.laPaz.fullDescription[2]}

${messages.laPaz.fullDescription[3]}

${messages.laPaz.fullDescription[4]}
          `,
          "6121246500",
          "526121088418",
        ];
      case "guadalajara":
        return [
          "Hotel Maioris Guadalajara",
          messages.guadalajara.brief1,
          messages.guadalajara.brief2,
          20.5959649,
          -103.3915505,
          messages.guadalajara.whatToDo,
          messages.guadalajara.shortWhatToDo,
          ["la-paz", "bellavista", "kumate"],
          [
            messages.services.rooms,
            messages.services.hygiene,
            messages.services.fireSafety,
            messages.services.wheelChair,
          ],
          `
${messages.guadalajara.fullDescription[0]}

${messages.guadalajara.fullDescription[1]}

${messages.guadalajara.fullDescription[2]}

${messages.guadalajara.fullDescription[3]}

${messages.guadalajara.fullDescription[4]}

${messages.guadalajara.fullDescription[5]}
          `,
          "3331330138",
          "523317691477",
        ];
      case "el-diez":
        return [
          "Hotel Maioris El Diez",
          messages.elDiez.brief1,
          messages.elDiez.brief2,
          24.737711,
          -107.4564873,
          messages.elDiez.whatToDo,
          messages.elDiez.shortWhatToDo,
          ["navolato", "bellavista", "la-paz"],
          [
            messages.services.rooms,
            messages.services.hygiene,
            messages.services.security24,
          ],
          `
${messages.elDiez.fullDescription[0]}

${messages.elDiez.fullDescription[1]}

${messages.elDiez.fullDescription[2]}

${messages.elDiez.fullDescription[3]}

${messages.elDiez.fullDescription[4]}

${messages.elDiez.fullDescription[5]}
          `,
          "6677605014",
          "526675031584",
        ];
      case "navolato":
        return [
          "Hotel Maioris Navolato",
          messages.navolato.brief1,
          messages.navolato.brief2,
          24.7648925,
          -107.6014242,
          messages.navolato.whatToDo,
          messages.navolato.shortWhatToDo,
          ["kumate", "bellavista", "la-paz"],
          [
            messages.services.rooms,
            messages.services.hygiene,
            messages.services.fireSafety,
            messages.services.wheelChair,
            messages.services.security24,
            messages.services.frigobar,
          ],
          `
${messages.navolato.fullDescription[0]}

${messages.navolato.fullDescription[1]}

${messages.navolato.fullDescription[2]}

${messages.navolato.fullDescription[3]}

${messages.navolato.fullDescription[4]}

${messages.navolato.fullDescription[5]}

${messages.navolato.fullDescription[6]}

${messages.navolato.fullDescription[7]}

${messages.navolato.fullDescription[8]}
          `,
          "6727274718",
          "526675039677",
        ];
      case "kumate":
        return [
          "Hotel Maioris Jesús Kumate",
          messages.kumate.brief1,
          messages.kumate.brief2,
          24.758003,
          -107.448389,
          messages.kumate.whatToDo,
          messages.kumate.shortWhatToDo,
          ["el-diez", "navolato", "guadalajara"],
          [
            messages.services.rooms,
            messages.services.hygiene,
            messages.services.fireSafety,
            messages.services.wheelChair,
            messages.services.security24,
          ],
          `
${messages.kumate.fullDescription[0]}

${messages.kumate.fullDescription[1]}

${messages.kumate.fullDescription[2]}

${messages.kumate.fullDescription[3]}

${messages.kumate.fullDescription[4]}

${messages.kumate.fullDescription[5]}
          `,
          "6677273636",
          "526673032104",
        ];
      case "bellavista":
        return [
          "Hotel Maioris Bellavista",
          messages.bellavista.brief1,
          messages.bellavista.brief2,
          24.817865,
          -107.4869909,
          messages.bellavista.whatToDo,
          messages.bellavista.shortWhatToDo,
          ["navolato", "guadalajara", "la-paz"],
          [
            messages.services.rooms,
            messages.services.hygiene,
            messages.services.fireSafety,
            messages.services.wheelChair,
            messages.services.security24,
            messages.services.frigobar,
          ],
          `
${messages.bellavista.fullDescription[0]}

${messages.bellavista.fullDescription[1]}

${messages.bellavista.fullDescription[2]}

${messages.bellavista.fullDescription[3]}

${messages.bellavista.fullDescription[4]}

${messages.bellavista.fullDescription[5]}
          `,
          "6675027071",
          "526675027071",
        ];
      default:
    }
  })();

  // const rooms = (() => {
  //   switch (hotel) {
  //     case 'la-paz':
  //       return [
  //         {
  //           name: 'Sencilla',
  //           beds: 1,
  //           details: [
  //             `2 ${messages.rooms.adults}`,
  //             `1 ${messages.rooms.kingSize}`,
  //           ],
  //         },
  //         {
  //           name: 'Doble deluxe',
  //           beds: 2,
  //           details: [
  //             `2 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `2 ${messages.rooms.matrimonialSize}`,
  //           ],
  //         },
  //       ];
  //     case 'guadalajara':
  //       return [
  //         {
  //           name: 'Estándar',
  //           beds: 2,
  //           details: [
  //             `2 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `2 ${messages.rooms.matrimonialSize}`,
  //           ],
  //         },
  //         {
  //           name: 'Ejecutiva',
  //           beds: 2,
  //           details: [
  //             `2 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `2 ${messages.rooms.queenSize}`,
  //           ],
  //         },
  //         {
  //           name: 'Lujo',
  //           beds: 2,
  //           details: [
  //             `2 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `2 ${messages.rooms.queenSize}`,
  //           ],
  //         },
  //         // {
  //         //   name: 'Sencilla',
  //         //   beds: 1,
  //         //   details: [
  //         //     `2 ${messages.rooms.adults}`,
  //         //     `1 ${messages.rooms.kingSize}`,
  //         //   ],
  //         // },
  //       ];
  //     case 'el-diez':
  //       return [
  //         {
  //           name: 'Doble regular',
  //           beds: 2,
  //           details: [
  //             `2 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `2 ${messages.rooms.matrimonialSize}`,
  //           ],
  //         },
  //         {
  //           name: 'Doble ejecutiva',
  //           beds: 2,
  //           details: [
  //             `2 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `2 ${messages.rooms.matrimonialSize}`,
  //           ],
  //         },
  //         {
  //           name: 'Doble deluxe',
  //           beds: 2,
  //           details: [
  //             `2 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `2 ${messages.rooms.queenSize}`,
  //           ],
  //         },
  //         {
  //           name: 'Triple',
  //           beds: 3,
  //           details: [
  //             `3 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `3 ${messages.rooms.matrimonialSize}`,
  //           ],
  //         },
  //       ];
  //     case 'navolato':
  //       return [
  //         {
  //           name: 'Deluxe',
  //           beds: 2,
  //           details: [
  //             `2 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `2 ${messages.rooms.queenSize}`,
  //           ],
  //         },
  //         {
  //           name: 'Triple',
  //           beds: 3,
  //           details: [
  //             `3 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `3 ${messages.rooms.queenSize}`,
  //           ],
  //         },
  //         {
  //           name: 'Master suite',
  //           beds: 3,
  //           details: [
  //             `4 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `2 ${messages.rooms.queenSize} ${messages.and} 1 ${messages.rooms.kingSize}`,
  //           ],
  //         },
  //       ]
  //     case 'kumate':
  //       return [
  //         {
  //           name: 'Doble regular',
  //           beds: 2,
  //           details: [
  //             `2 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `2 ${messages.rooms.matrimonialSize}`,
  //           ],
  //         },
  //         {
  //           name: 'Doble ejecutiva',
  //           beds: 2,
  //           details: [
  //             `2 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `2 ${messages.rooms.matrimonialSize}`,
  //           ],
  //         },
  //         {
  //           name: 'Triple',
  //           beds: 3,
  //           details: [
  //             `3 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `3 ${messages.rooms.matrimonialSize}`,
  //           ],
  //         },
  //       ];
  //     case 'bellavista':
  //       return [
  //         {
  //           name: 'Doble deluxe',
  //           beds: 2,
  //           details: [
  //             `2 ${messages.rooms.adults}`,
  //             `2 ${messages.rooms.children}`,
  //             `2 ${messages.rooms.queenSize}`,
  //           ],
  //         },
  //         {
  //           name: 'De lujo',
  //           beds: 1,
  //           details: [
  //             `2 ${messages.rooms.adults}`,
  //             `1 ${messages.rooms.kingSize}`,
  //           ],
  //         },
  //       ];
  //     default:
  //
  //   }
  // })();

  // const comments = (() => {
  //   switch (hotel) {
  //     case 'la-paz':
  //       return [
  //         {
  //           avatar: '',
  //           name: 'Julio Nunez',
  //           stars: 5,
  //           comment: 'Excelente',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Ojcastro Cadeco',
  //           stars: 5,
  //           comment: 'Comida muy rica servicio muy agradable, cómodas instalaciones',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Alex Hernandez Garcia',
  //           stars: 5,
  //           comment: 'Excelente servicio, sprecio y lugar. Comida y bar riquísimo, cómodo y completo en habitaciones',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Ángel González',
  //           stars: 5,
  //           comment: 'De las mejores opciones para visitar La Paz. Aunque no se encuentra en el centro o cerca del malecón, lo recomiendo demasiado. Además de encontrarse a sólo 5 minutos del aeropuerto, sus instalaciones son excelentes; habitaciones modernas, baños amplios y comida deliciosa. Los precios son excelentes y lo mejor es el club de playa. Fue más de lo que esperaba no dudaría que a mí regreso me hospedara de tiempo completo aquí, tiene un Oxxo al lado y puedes meter alimentos y bebidas sin problema. Estoy más que satisfecho con mi visita',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Daniel Santana camarillo',
  //           stars: 5,
  //           comment: 'Un lugar muy tranquilo y agradable restaurante de primer nivel',
  //         },
  //       ];
  //     case 'guadalajara':
  //       return [
  //         {
  //           avatar: '',
  //           name: 'Carlos M Carrion Aguilar',
  //           stars: 5,
  //           comment: 'Excelente lugar y servicio',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Viridiana Altamirano',
  //           stars: 5,
  //           comment: 'Servicio limpio',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Carolina Pérez',
  //           stars: 4,
  //           comment: 'Las instalaciones y el servicio están bien. Lo recomiendo',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Alfre Marti',
  //           stars: 5,
  //           comment: 'Es de cajón siempre que vamos a Guadalajara muy buen lugar limpio agradables descansas a tus anchas y servicio al cuarto estacionamiento en frente de la habitación wifi sin interrupciones restaurante en unos pasos y la comida de muy buena calidad',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Rafael Chavez',
  //           stars: 5,
  //           comment: 'Excelente lugar',
  //         },
  //       ];
  //     case 'el-diez':
  //       return [
  //         {
  //           avatar: '',
  //           name: 'N Gálvez',
  //           stars: 5,
  //           comment: 'Excelente lugar',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Javier Romero Furlong',
  //           stars: 4,
  //           comment: 'Buenas instalaciones, el restaurante ofrece muy buen café y alimentos de muy buena calidad',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Camila Mtz',
  //           stars: 5,
  //           comment: 'Excelente lugar y limpieza',
  //         },
  //         {
  //           avatar: '',
  //           name: 'alejandro moreno',
  //           stars: 5,
  //           comment: 'Muy amplio y comodo estacionamiento para traile',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Miguel Angel Valdez cruz',
  //           stars: 5,
  //           comment: 'Lacomida esta riquisima',
  //         },
  //       ];
  //     case 'navolato':
  //       return [
  //         {
  //           avatar: '',
  //           name: 'Luis Cruz',
  //           stars: 5,
  //           comment: 'Excelente servicio muy limpio buen personal',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Martha Beatriz Cañedo Morachis',
  //           stars: 5,
  //           comment: 'Excelente todo... Buenas atención e instalaciones... lo recomiendo👌',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Abigail vega',
  //           stars: 5,
  //           comment: 'Muy agusto, el área de la alberca super cómoda',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Dora Alicia Beltrán Garcia',
  //           stars: 5,
  //           comment: 'Muy bonito mucha tranquilidad me encanto estar ay',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Rich Cabrera',
  //           stars: 5,
  //           comment: 'Bastante cómodo, limpio y buena atención, cabe señalar que es la mejor opción entre Culiacán y Navolato. Totalmente recomendable.',
  //         },
  //       ];
  //     case 'kumate':
  //       return [
  //         {
  //           avatar: '',
  //           name: 'Alejandro Alvarez Malagon',
  //           stars: 5,
  //           comment: 'Buen lugar para descansar',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Jose Zanabria',
  //           stars: 5,
  //           comment: 'Excelente trato y amabilidad del personal',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Cesar Lozano',
  //           stars: 5,
  //           comment: 'Excelente 👌',
  //         },
  //         {
  //           avatar: '',
  //           name: 'Genaro Salome Macedo Perez',
  //           stars: 5,
  //           comment: 'Excelente servicio y atención, habitaciones limpias y sanitizadas. El restaurante con gran variedad de alimentos de la región además de muy buen sazón',
  //         },
  //         {
  //           avatar: '',
  //           name: 'ángel hernandez',
  //           stars: 5,
  //           comment: 'Muy bueno se los recomiendo',
  //         },
  //       ];
  //     case 'bellavista':
  //     default:
  //       return [
  //         {
  //           avatar: '',
  //           name: 'Santiago Galguera Roel',
  //           stars: 5,
  //           comment: '',
  //         },
  //       ];
  //   }
  // })();

  const [
    services,
    firstColumnStart,
    firstColumnEnd,
    secondColumnStart,
    secondColumnEnd,
  ] = (() => {
    switch (hotel) {
      case "la-paz":
        return [
          [
            "alberca",
            "wifi",
            "restaurant",
            "estacionamiento",
            "extinguidor",
            "silla-ruedas",
            "sala-juntas",
            "bilingue",
          ],
          0,
          4,
          4,
          8,
        ];
      case "guadalajara":
        return [
          [
            "wifi",
            "estacionamiento",
            "sala-juntas",
            "extinguidor",
            "silla-ruedas",
          ],
          0,
          3,
          3,
          5,
        ];
      case "el-diez":
        return [
          [
            "wifi",
            "estacionamiento",
            "sala-juntas",
            "extinguidor",
            "silla-ruedas",
          ],
          0,
          3,
          3,
          5,
        ];
      case "navolato":
        return [
          [
            "alberca",
            "aire",
            "wifi",
            "estacionamiento",
            "servicio",
            "extinguidor",
            "silla-ruedas",
            "tv",
          ],
          0,
          4,
          4,
          8,
        ];
      case "kumate":
        return [
          ["jardin", "wifi", "estacionamiento", "extinguidor", "silla-ruedas"],
          0,
          3,
          3,
          5,
        ];
      case "bellavista":
        return [
          [
            "alberca",
            "aire",
            "wifi",
            "estacionamiento",
            "servicio",
            "extinguidor",
            "silla-ruedas",
            "tv",
          ],
          0,
          4,
          4,
          8,
        ];
      default:
    }
  })();

  const getService = (service) => {
    switch (service) {
      case "alberca":
        return (
          <Service>
            <img src="/iconos/pool.svg" alt="" />
            {messages.services.alberca}
          </Service>
        );
      case "wifi":
        return (
          <Service>
            <img src="/iconos/wifi.svg" alt="" />
            {messages.services.wifi}
          </Service>
        );
      case "restaurant":
        return (
          <Service>
            <img src="/iconos/restaurant.svg" alt="" />
            {messages.services.restaurant}
          </Service>
        );
      case "estacionamiento":
        return (
          <Service>
            <img src="/iconos/parking.svg" alt="" />
            {messages.services.estacionamiento}
          </Service>
        );
      case "extinguidor":
        return (
          <Service>
            <img src="/iconos/extinguisher.svg" alt="" />
            {messages.services.extinguidor}
          </Service>
        );
      case "silla-ruedas":
        return (
          <Service>
            <img src="/iconos/accessible.svg" alt="" />
            {messages.services.sillaRuedas}
          </Service>
        );
      case "sala-juntas":
        return (
          <Service>
            <img src="/iconos/work.svg" alt="" />
            {messages.services.salaJuntas}
          </Service>
        );
      case "bilingue":
        return (
          <Service>
            <img src="/iconos/person.svg" alt="" />
            {messages.services.bilingue}
          </Service>
        );
      case "computadora":
        return (
          <Service>
            <img src="/iconos/laptop.svg" alt="" />
            {messages.services.computadora}
          </Service>
        );
      case "aire":
        return (
          <Service>
            <img src="/iconos/snow.svg" alt="" />
            {messages.services.aire}
          </Service>
        );
      case "servicio":
        return (
          <Service>
            <img src="/iconos/person.svg" alt="" />
            {messages.services.servicio}
          </Service>
        );
      case "tv":
        return (
          <Service>
            <img src="/iconos/laptop.svg" alt="" />
            {messages.services.tv}
          </Service>
        );
      case "jardin":
        return (
          <Service>
            <img src="/iconos/grass.svg" alt="" />
            {messages.services.jardin}
          </Service>
        );
      default:
    }
  };

  // TODO: Quitar validacion
  const minDate =
    hotel === "guadalajara" ? "2021-08-01" : moment().format("YYYY-MM-DD");
  const departureMinDate = moment(body.llegada ? body.llegada : new Date())
    .add(1, "days")
    .format("YYYY-MM-DD");

  return (
    <Container>
      <Header />
      <Gallery images={images} />
      <div className="detail">
        <div className="left-section">
          <h1>{name}</h1>
          <p>{firstParagraph}</p>
          <p>{secondParagraph}</p>
          <Divider />
          <h2>{messages.rooms.rooms}</h2>
          <div className="rooms">
            {rooms.map((room) => (
              <Room key={room.name}>
                <div className="name">{room.name}</div>
                <div className="beds">
                  {times(room.beds, (i) => (
                    <BedIcon />
                  ))}
                </div>
                {room.details.map((detail) => (
                  <div className="room-detail">{detail}</div>
                ))}
              </Room>
            ))}
          </div>
          <Divider />
          <h2>{messages.services.services}</h2>
          <div className="services">
            <div>
              {services
                .slice(firstColumnStart, firstColumnEnd)
                .map((service) => getService(service))}
            </div>
            <div>
              {services
                .slice(secondColumnStart, secondColumnEnd)
                .map((service) => getService(service))}
            </div>
          </div>
          <button
            className="see-more-button"
            onClick={() => setMoreServicesOpen(true)}
          >
            {messages.seeMore}
          </button>
          <Divider />
        </div>
        <ReservationForm>
          <h1>{name}</h1>
          <h3>{messages.welcomeMessage}</h3>
          <div className="space-between">
            <Input
              width="calc(50% - 8px)"
              placeholder={messages.arrive}
              type="date"
              onChange={(e) => handleSetBody("llegada", e.target.value)}
              value={body.llegada}
              min={minDate}
            />
            <Input
              width="calc(50% - 8px)"
              placeholder={messages.exit}
              type="date"
              onChange={(e) => handleSetBody("salida", e.target.value)}
              value={body.salida}
              min={departureMinDate}
            />
          </div>
          {/* false && (
            <div className="space-between">
              <Input
                width="calc(50% - 8px)"
                placeholder={messages.adults}
                type="number"
                onChange={e => handleSetBody('adultos', e.target.value)}
              />
              <Input
                width="calc(50% - 8px)"
                placeholder={messages.children}
                type="number"
                onChange={e => handleSetBody('ninios', e.target.value)}
              />
            </div>
            <Input
              width="300px"
              placeholder={messages.code}
              type="text"
              onChange={e => handleSetBody('codigo', e.target.value)}
            />
          ) */}
          <Link to={getUrlBook()}>{messages.book}</Link>
        </ReservationForm>
      </div>
      <div className="rest-content">
        <Location>
          <h2>{messages.location}</h2>
          <LoadScript googleMapsApiKey="AIzaSyBn_QTAsaNNI9gmqF9wcalcUNSXUwziltc">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{ lat, lng }}
              zoom={16}
            >
              {false && (
                <Marker
                  id="marker"
                  position={{ lat, lng }}
                  icon={{
                    url: "./images/custom-marker.svg",
                    anchor,
                    scaledSize,
                  }}
                />
              )}
            </GoogleMap>
          </LoadScript>
        </Location>
        <Activities>
          <h2>{locationTitle}</h2>
          <div className="description">{locationText}</div>
          <button
            className="see-more-button"
            onClick={() => setWhatToDoOpen(true)}
          >
            {messages.seeMore}
          </button>
        </Activities>
        <Comments>
          <h2>Comentarios</h2>
          <div className="comments">
            {comments.map((comment) => (
              <CommentItem>
                <div className="person">
                  <Avatar>
                    <span>{comment.name.charAt(0)}</span>
                  </Avatar>
                  <div>
                    <div className="client-name">{comment.name}</div>
                    <div className="stars">
                      {times(comment.stars, (i) => (
                        <img src="/iconos/star.svg" alt="" />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="text">{comment.comment}</div>
              </CommentItem>
            ))}
          </div>
        </Comments>
        <MoreHotels>
          <h2>{messages.knowMoreDestinations}</h2>
          <div className="hotels">
            {moreHotels.map((hotel) => (
              <Card key={hotel} hotel={hotel} data={data.places} />
            ))}
          </div>
        </MoreHotels>
      </div>
      <Footer />
      <Dialog
        open={moreServicesOpen}
        onClose={() => setMoreServicesOpen(false)}
      >
        <MoreServices>
          <h2>{messages.moreServices}</h2>
          {restServices.map((item) => (
            <div className="item">{item}</div>
          ))}
          <IconButton
            className="close-icon"
            onClick={() => setMoreServicesOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </MoreServices>
      </Dialog>
      <Dialog open={whatToDoOpen} onClose={() => setWhatToDoOpen(false)}>
        <MoreServices width="800px">
          <h2>{locationTitle}</h2>
          {whatToDo}
          <IconButton
            className="close-icon"
            onClick={() => setWhatToDoOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </MoreServices>
      </Dialog>
      <a className="call-button" href={`tel:${hotelPhone}`}>
        <Fab size="medium">
          <CallIcon />
        </Fab>
      </a>
      <a
        className="whatsapp-button"
        href={`https://api.whatsapp.com/send?phone=${whatsAppPhone}`}
        target="_blank"
        rel="noreferrer"
      >
        <Fab size="medium">
          <img src={WhatsappIcon} alt="" />
        </Fab>
      </a>
    </Container>
  );
}

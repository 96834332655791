/**
 * Gallery
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { isNumber } from 'lodash';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import useMediaQuery from '@material-ui/core/useMediaQuery'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

import {
  Container,
  FullScreenGallery,
  ContainerSalon,
  ResponsivePicture,
} from './styles';

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  swipeToSlide: true,
  centerMode: true,
  className: 'custom-slider',
  centerPadding: '0',
};

export default function Gallery({
  images = [],
  type = 'banner',
  hidden = false,
  open = false,
  onClose,
}) {
  const [activeImage, setActiveImage] = useState(null);

  const isResponsive = useMediaQuery('@media only screen and (max-width: 768px)');

  useEffect(() => {
    if (open) setActiveImage(0);
  }, [open]);

  const handleOpenFullScreenGallery = index => () => {
    setActiveImage(index);
    if (index === null && typeof onClose === 'function') onClose();
  };

  const handleBeforePicture = () => {
    setActiveImage(_activeImage => _activeImage === 0 ? images.length - 1 : _activeImage - 1);
  };

  const handleNextPicture = () => {
    setActiveImage(_activeImage => _activeImage === images.length - 1 ? 0 : _activeImage + 1);
  };

  return (
    <React.Fragment>
      {isResponsive && !hidden && (
        <Slider {...settings}>
          {images.map((image, index) => (
            <ResponsivePicture
              src={image}
              alt={image}
              onClick={handleOpenFullScreenGallery(index)}
            />
          ))}
        </Slider>
      )}
      {type === 'banner' && !hidden && !isResponsive && (
        <Container>
          <img
            src={images[0]}
            alt="Imagen principal hotel"
            className="main-pic"
            onClick={handleOpenFullScreenGallery(0)}
          />
          <div className="secondary-section">
            <img
              src={images[1]}
              alt="Imagen secundaria hotel"
              className="pic"
              onClick={handleOpenFullScreenGallery(1)}
            />
            <img
              src={images[2]}
              alt="Imagen secundaria hotel"
              className="pic"
              onClick={handleOpenFullScreenGallery(2)}
            />
            <img
              src={images[3]}
              alt="Imagen secundaria hotel"
              className="pic"
              onClick={handleOpenFullScreenGallery(3)}
            />
            <img
              src={images[4]}
              alt="Imagen secundaria hotel"
              className="pic"
              onClick={handleOpenFullScreenGallery(4)}
            />
          </div>
        </Container>
      )}
      {type === 'salon' && !hidden && !isResponsive && (
        <ContainerSalon>
          <img
            src={images[0]}
            alt="Imagen principal hotel"
            className="main-pic"
            onClick={handleOpenFullScreenGallery(0)}
          />
          <img
            src={images[1]}
            alt="Imagen principal hotel"
            className="main-pic"
            onClick={handleOpenFullScreenGallery(1)}
          />
          <img
            src={images[2]}
            alt="Imagen secundaria hotel"
            className="pic"
            onClick={handleOpenFullScreenGallery(2)}
          />
          <img
            src={images[3]}
            alt="Imagen secundaria hotel"
            className="pic"
            onClick={handleOpenFullScreenGallery(3)}
          />
          <img
            src={images[4]}
            alt="Imagen secundaria hotel"
            className="pic"
            onClick={handleOpenFullScreenGallery(4)}
          />
          <img
            src={images[5]}
            alt="Imagen secundaria hotel"
            className="pic"
            onClick={handleOpenFullScreenGallery(4)}
          />
        </ContainerSalon>
      )}
      {isNumber(activeImage) && (
        <FullScreenGallery>
          <div className="action">
            <IconButton onClick={handleBeforePicture}>
              <NavigateBeforeIcon />
            </IconButton>
          </div>
          {isResponsive ? (
            <div className="full-screen-gallery">
              <Slider {...settings} autoplay={false}>
                {images.map((image) => (
                  <div className="responsive-item">
                    <img src={image} alt="" className="active-image" />
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <img src={images[activeImage]} alt="" className="active-image" />
          )}
          <div className="action">
            <IconButton onClick={handleNextPicture}>
              <NavigateNextIcon />
            </IconButton>
          </div>
          <IconButton className="close-icon" onClick={handleOpenFullScreenGallery(null)}>
            <CloseIcon />
          </IconButton>
          <div className="count">
            {`${activeImage + 1} / ${images.length}`}
          </div>
        </FullScreenGallery>
      )}
    </React.Fragment>
  );
}

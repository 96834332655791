import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { get } from 'lodash';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';

import Footer from '../../components/Footer';

import messages from '../../getMessages';

import {
  Container,
  Banner,
  Item,
  DrawerContainer,
} from './styles';

const items = [
  {
    key: 'la-paz',
    name: messages.restaurant.laPaz,
    schedule: '7:00am  a 11:00pm',
    location: 'Carretera Transpeninsular al Norte Km. 8.5 S/N, Ejido Chametla. C.P. 23205.',
    link: '/menu/la-paz',
    image: '/restaurantes/paz.jpg',
  },
  {
    key: 'guadalajara',
    name: messages.restaurant.guadalajara,
    schedule: '7:00am a 11:00pm',
    location: 'Anillo Periférico Sur No. 7200, Fracc. Industrial El Camino, C.P. 45601.',
    link: '/menu/guadalajara',
    image: '/restaurantes/guadalajara.jpg',
  },
  {
    key: 'kumate',
    name: messages.restaurant.kumate,
    schedule: '7:00am a 11:00pm',
    location: 'Boulevard Dr. Jesús Kumate Rodríguez #2698 Sur Col. Ejido Barrancos',
    link: '/menu/culiacan',
    image: '/restaurantes/kumate.jpg',
  },
  {
    key: 'el-diez',
    name: messages.restaurant.elDiez,
    schedule: '7:00am a 11:00pm',
    location: 'Carretera Culiacán-Eldorado Km 4.5 #7220 Culiacán, Sinaloa, México. C.P. 80155.',
    link: '/menu/culiacan',
    image: '/restaurantes/diez.jpg',
  },
];

const language = localStorage.getItem('language') || navigator.language || navigator.userLanguage;
const newLanguage = language.includes('es') ? 'en' : 'es';
const newLanguageResponsive = language.includes('es') ? 'English' : 'Español';

export default function Restaurants({ children, data }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClose = () => setAnchorEl(null);

  const handleOpenMenu = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleChangeLanguage = () => {
    try {
      localStorage.setItem('language', newLanguage);
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  const isResponsive = useMediaQuery('@media only screen and (max-width: 992px)');

  return (
    <Container>
      <Banner background={get(data, 'landingConfig.restaurants', '')}>
        <div className="layer" />
        <div className="over-content">
          <div className="content">
            <div className="space-between top-section">
              <div className="logo-section">
                <Link to="/">
                  <img src="/logo.png" alt="Logo hoteles Maioris" />
                </Link>
              </div>
              <nav>
                <Link to="/covid-19">COVID-19</Link>
                <Link to="/restaurantes">{messages.restaurants}</Link>
                <Link to="/eventos" onClick={handleOpenMenu}>{messages.events}</Link>
                <Link to="/contacto">{messages.contact}</Link>
                <Link to="/reservar" className="reservar-button">
                  {messages.reservation}
                </Link>
                <button className="language" onClick={handleChangeLanguage}>
                  <span>{newLanguage}</span>
                </button>
              </nav>
              <MenuIcon onClick={() => setMenuOpen(true)} />
            </div>
          </div>
        </div>
      </Banner>
      <h1>{messages.restaurants}</h1>
      <div className="description">
        {messages.restaurant.description}
      </div>
      <div className="restaurants-list">
        {items.map(item => (
          <Item key={item.name}>
            <img
              src={get((get(data, 'places') || []).find(p => p.place === item.key), 'restaurantThumbnail', '')}
              alt={item.name}
            />
            <div className="item-content">
              <h4>{item.name}</h4>
              <div className="item-detail">
                <img src="/iconos/clock.svg" alt="" />
                {item.schedule}
              </div>
              <div className="item-detail">
                <img src="/iconos/location.svg" alt="" />
                {item.location}
              </div>
              <Link to={item.link} className="link-menu">
                {messages.restaurant.seeMenu}
              </Link>
            </div>
          </Item>
        ))}
      </div>
      <Footer />
      {!isResponsive && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Link to="/via-esperanza">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Salón Vía Esperanza
            </MenuItem>
          </Link>
          <Link to="/via-petra">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Salón Vía Petra
            </MenuItem>
          </Link>
          <Link to="/maioris-beach-club">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Maioris Beach Club
            </MenuItem>
          </Link>
        </Menu>
      )}
      <Drawer anchor="right" open={menuOpen} onClose={handleCloseMenu}>
        <DrawerContainer>
          <img src="/logo.png" alt="Logo hoteles Maioris" />
          <nav>
            <Link to="/covid-19" onClick={handleCloseMenu}>
              COVID-19
            </Link>
            <Link to="/restaurantes" onClick={handleCloseMenu}>
              {messages.restaurants}
            </Link>
            <Link to="/eventos" onClick={handleOpenMenu}>
              {messages.events}
            </Link>
            <Link to="/contacto" onClick={handleCloseMenu}>
              {messages.contact}
            </Link>
            <Link to="/reservar" onClick={handleCloseMenu} className="reservar-button">
              {messages.reservation}
            </Link>
            <Button
              color="secondary"
              className="language"
              variant="contained"
              onClick={handleChangeLanguage}
            >
              {newLanguageResponsive}
            </Button>
          </nav>
        </DrawerContainer>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Link to="/via-esperanza">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Salón Vía Esperanza
            </MenuItem>
          </Link>
          <Link to="/via-petra">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Salón Vía Petra
            </MenuItem>
          </Link>
          <Link to="/maioris-beach-club">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Maioris Beach Club
            </MenuItem>
          </Link>
        </Menu>
      </Drawer>
    </Container>
  );
}

import styled from 'styled-components';
// import { media } from '../../helper';

export const Container = styled.div`
  width: 100%;
  ${props => !props.isButton && 'min-width: 100%;'}
  height: 340px;
  max-width: 100%;
  background-color: #D8D8D8;
  background-image: url('${props => props.image}');
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(153,152,152,0.50);
  color: #FFFFFF;
  position: relative;
  background-size: cover;
  background-position: center;
  transition: 0.25s opacity;

  & .bottom {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0 0 4px 4px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 12px 12px 24px;
  }

  & .bottom h4 {
    font-size: 14px;
    margin-bottom: 14px;
  }

  & .bottom .location {
    max-width: 200px;
    font-size: 10px;
    margin-bottom: 8px;
  }

  & .bottom .phones {
    max-width: 200px;
    font-size: 10px;
    display: flex;
    align-items: center;
  }

  & .bottom .phones svg {
    font-size: 10px;
    margin-right: 4px;
  }

  & .see-more {
    position: absolute;
    bottom: 16px;
    right: 0;
    background-color: #D8B93C;
    border-radius: 4px 0 0 4px;
    padding: 8px 32px;
    color: #ffffff;
    font-size: 13px;
  }

  & .whatsapp-button {
    position: absolute;
    bottom: 40px;
    right: 24px;
    z-index: 9;
  }

  & .whatsapp-button button {
    background-color: #37d043;
  }

  & .whatsapp-button svg {
    color: #ffffff;
  }

  & .whatsapp-button-card button {
    background-color: #37d043;
    position: absolute;
    bottom: 60px;
    right: 24px;
    z-index: 9;
  }

  & .whatsapp-button-card button img {
    width: 28px;
    height: 28px;
  }

  ${props => props.isButton && '&:hover{opacity: 0.7;}'}
`;

import React from 'react';
import styled from 'styled-components';

import { mediaQuerySmall } from '../../helper';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & main {
    padding: 80px 24px;
    flex-grow: 1;
  }

  & .children {
    max-width: 1280px;
    margin: 40px auto 0;
  }

  ${mediaQuerySmall} {
    & .children {
      margin-top: 40px;
    }

    & main {
      padding: 80px 16px;
    }
  }
`;

export default function MainLayout({ children }) {
  return (
    <Content>
      <main>
        <div className="children">
          {children}
        </div>
      </main>
    </Content>
  );
}

// import React from 'react';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { get } from 'lodash';

import useMediaQuery from '@material-ui/core/useMediaQuery'

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import messages from '../../getMessages';

import { Container } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc= '/pdf.worker.js';

const lang = localStorage.getItem('language') || navigator.language || navigator.userLanguage;

const isSpanish = lang.includes('es');

export default function FileView({ view, data }) {
  const [pagesTotal, setPagesTotal] = useState(0);

  const [title, link] = (() => {
    switch (view) {
      case 'menu-guadalajara':
        return [
          messages.restaurant.menus.guadalajara.title,
          get((get(data, 'places') || []).find(p => p.place === 'guadalajara'), isSpanish ? 'menu' : 'menuEnglish'),
          // messages.restaurant.menus.guadalajara.link,
        ];
      case 'menu-culiacan':
        return [
          messages.restaurant.menus.culiacan.title,
          // messages.restaurant.menus.culiacan.link,
          get((get(data, 'places') || []).find(p => p.place === 'kumate'), isSpanish ? 'menu' : 'menuEnglish'),
        ];
      case 'menu-la-paz':
        return [
          messages.restaurant.menus['la-paz'].title,
          // messages.restaurant.menus['la-paz'].link,
          get((get(data, 'places') || []).find(p => p.place === 'la-paz'), isSpanish ? 'menu' : 'menuEnglish'),
        ];
      case 'room-service-bellavista':
        return [
          messages.restaurant['room-service'].bellavista.title,
          // messages.restaurant['room-service'].bellavista.link,
          get((get(data, 'places') || []).find(p => p.place === 'bellavista'), isSpanish ? 'roomService' : 'roomServiceEnglish'),
        ];
      case 'room-service-culiacan':
        return [
          messages.restaurant['room-service'].culiacan.title,
          // messages.restaurant['room-service'].culiacan.link,
          get((get(data, 'places') || []).find(p => p.place === 'kumate'), isSpanish ? 'roomService' : 'roomServiceEnglish'),
        ];
      case 'room-service-guadalajara':
        return [
          messages.restaurant['room-service'].guadalajara.title,
          // messages.restaurant['room-service'].guadalajara.link,
          get((get(data, 'places') || []).find(p => p.place === 'guadalajara'), isSpanish ? 'roomService' : 'roomServiceEnglish'),
        ];
      case 'room-service-la-paz':
        return [
          messages.restaurant['room-service']['la-paz'].title,
          // messages.restaurant['room-service']['la-paz'].link,
          get((get(data, 'places') || []).find(p => p.place === 'la-paz'), isSpanish ? 'roomService' : 'roomServiceEnglish'),
        ];
      case 'room-service-navolato':
        return [
          messages.restaurant['room-service'].navolato.title,
          // messages.restaurant['room-service'].navolato.link,
          get((get(data, 'places') || []).find(p => p.place === 'navolato'), isSpanish ? 'roomService' : 'roomServiceEnglish'),
        ];
      case 'aviso-privacidad':
        return [
          messages.noticePrivacy,
          get(data, 'landingConfig.noticePrivacy', ''),
          // messages.noticePrivacyLink,
        ];
      case 'aviso-legal':
        return [
          messages.noticeLegal,
          get(data, 'landingConfig.noticeLegal', ''),
          // messages.noticeLegalLink,
        ];
      case 'terminos-condiciones':
        return [
          messages.termConditions,
          get(data, 'landingConfig.termConditions', ''),
          // messages.termConditionsLink,
        ];
      default:

    }
  })();

  const onDocumentLoadSuccess = payload => setPagesTotal(payload.numPages);

  // const iframeUrl = `https://docs.google.com/viewerng/viewer?url=${link}&embedded=true`;

  const isResponsive = useMediaQuery('@media only screen and (max-width: 768px)');

  const pageWidth = window.screen.width - 32;
  const responsiveWidth = pageWidth > 600 ? 600 : pageWidth;

  return (
    <Container>
      <Header />
      <div className="content">
        <h1>{title}</h1>
        <div className="pdf-content">
          <Document
            file={link}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={view === 'aviso-privacidad' ? 'Loading...' : messages.loadingMenu}
            error={messages.errorPdf}
          >
            {
              Array.from(
                new Array(pagesTotal),
                (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={isResponsive ? responsiveWidth : '600'}
                    loading=" "
                  />
                ),
              )
            }
          </Document>
        </div>
        {/*<iframe
          title={title}
          src={link}
          frameBorder="0"
        />*/}
      </div>
      <Footer />
    </Container>
  );
}

import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { get } from 'lodash';
import queryString from 'query-string';
import Numeral from 'numeral';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Validator from 'validator';
import { Helmet } from "react-helmet";
import MaskedInput from 'react-text-mask'

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Card from '../../components/Card';
import RoomCard from '../../components/RoomCard';
import Footer from '../../components/Footer';
// import Select from '../../components/Select';
import OrderItem from '../../components/OrderItem';

import messages from '../../getMessages';
import { BASE_URL, hotelsMap, CONEKTA_KEYS } from '../../helper';

import {
  Container,
  ResumeOrder,
} from './styles';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialBody = {
  hotel: null,
  room: null,
  llegada: '',
  salida: '',
  adultos: '',
  ninios: '',
  codigo: '',
  nombre: '',
  numero: '',
  email: '',
  card: '',
  nameOnCard: '',
  expiration: '',
  cvc: '',
};

const curr = new Date();
curr.setDate(curr.getDate() + 3);
const defaultDate = curr.toISOString().substr(0,10);

const steps = [
  messages.steps.select,
  messages.steps.dates,
  messages.steps.personal,
];

const defaultCardErrors = {
  number: false,
  expiration: false,
  cvc: false,
}

export default function Reservation({ children, history, data }) {
  const isResponsive = useMediaQuery('@media only screen and (max-width: 992px)');

  const [body, setBody] = useState({ ...initialBody });
  const [successOpen, setSuccessOpen] = useState('');
  const [errorOpen, setErrorOpen] = useState('');
  const [avisoPrivacidadChecked, setAvisoPrivacidadChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  // const [avisoLegalChecked, setAvisoLegalChecked] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [baseRooms, setBaseRooms] = useState([]);
  const [order, setOrder] = useState([]);
  const [searchState, setSearchState] = useState(false);
  const [cardErrors, setCardErrors] = useState({ ...defaultCardErrors });
  const [loading, setLoading] = useState(false);

  // TODO: Quitar esta validacion despues de Julio
  const minDate = moment().format('YYYY-MM-DD');
  const maxDateArrival = moment().add(1, 'years').format('YYYY-MM-DD');
  const departureMinDate = moment(body.llegada ? body.llegada : new Date()).add(1, 'days').format('YYYY-MM-DD');
  const departureMaxDate = moment(body.llegada ? body.llegada : new Date()).add(1, 'months').format('YYYY-MM-DD');

  useEffect(() => {
    getUrlData();
  }, []);

  useEffect(() => {
    if (baseRooms && !searchState && body.llegada && body.salida) {
      handleCheckAvailability();
      setSearchState(true);
    }
  }, [baseRooms]);

  useEffect(() => {
    if (isResponsive) {
      setBody({
        ...initialBody,
        llegada: defaultDate,
        salida: defaultDate,
      });
    }
  }, [isResponsive]);

  useEffect(() => {
    if (selectedHotel) fetchRooms();
  }, [selectedHotel]);

  const getUrlData = () => {
    const {
      hotel,
      llegada,
      salida,
      adultos,
      ninios,
      codigo,
    } = queryString.parse(history.location.search);
    setBody(_body => ({
      ..._body,
      llegada: llegada || '',
      salida: salida || '',
      adultos: adultos || '',
      ninios: ninios || '',
      codigo: codigo || '',
    }));
    if (hotel) {
      setSelectedHotel(hotel);
      setActiveStep(1);
      return;
    }
    setSearchState(true);
  };

  const fetchRooms = async () => {
    try {
      const response = await Axios.get(`${BASE_URL}/rooms/${selectedHotel}`);
      setBaseRooms(get(response, 'data.data', []));
      if (body.llegada && body.salida) setSearchState(_searchState => !_searchState);
    } catch (e) {
      // console.log('ERROR', e);
      setErrorOpen(messages.errorRooms);
    }
  }

  const handleCheckAvailability = async () => {
    try {
      const response = await Axios
        .post(`${BASE_URL}/configurations/check-availability`,
          {
            startDate: body.llegada,
            endDate: body.salida,
            rooms: baseRooms.map(room => room.id),
            hotel: selectedHotel,
          }
        );
      const configurations = get(response, 'data.data') || [];
      setBaseRooms(_baseRooms => _baseRooms.map(room => ({
        ...room,
        ...configurations.find(c => c.id === room.id),
      })));
    } catch (e) {
      setErrorOpen(messages.errorAvailability);
    }
  }

  const handleRequestReservation = async () => {
    try {
      setLoading(true);
      if (
        !body.llegada ||
        !body.salida ||
        !body.nombre ||
        !body.numero ||
        !body.email ||
        !body.card ||
        !body.nameOnCard ||
        !body.expiration ||
        !body.cvc
      ) {
        setErrorOpen(messages.incomplete);
        return;
      }

      if (!Validator.isEmail(body.email)) {
        setErrorOpen(messages.badEmail);
        return;
      }

      if (moment(body.llegada).isBefore(minDate) || moment(body.salida).isBefore(body.llegada)) {
        setErrorOpen(messages.errorDates);
        return;
      }

      if (
        (body.adultos > get(selectedRoom, 'adults', 0)) ||
        (body.ninios > get(selectedRoom, 'children', 0))
      ) {
        setErrorOpen(messages.errorPeople);
        return;
      }

      if (body.numero.length < 10 || !Validator.isMobilePhone(body.numero)) {
        setErrorOpen(messages.badPhone);
        return;
      }

      const [month, year] = body.expiration.split(' / ');

      setCardErrors({
        number: !Conekta.card.validateNumber(body.card),
        expiration: !Conekta.card.validateExpirationDate(month, `20${year}`),
        cvc: !Conekta.card.validateCVC(body.cvc),
      });

      if (
        !Conekta.card.validateNumber(body.card) ||
        !Conekta.card.validateExpirationDate(month, `20${year}`) ||
        !Conekta.card.validateCVC(body.cvc)
      )
        return;

      let totalDays = 0;
      let auxDate = moment(body.llegada);
      do {
        totalDays += 1;
        auxDate = moment(body.llegada).add(totalDays, 'days');
      } while (moment(auxDate).isBefore(body.salida));
      const [totalPrice, totalAdults, totalChildren] = order.reduce(
        (total, item) => {
          const [price, adults, children] = total;
          return [
            price + item.price,
            adults + item.adults,
            children + item.children,
          ];
        },
        [0, 0, 0]
      );

      const KEY = CONEKTA_KEYS[selectedHotel];
      Conekta.setPublicKey(KEY);
      Conekta.setLanguage("es");
      const tokenParams = {
        card: {
          number: body.card,
          name: body.nameOnCard,
          exp_year: `20${year}`,
          exp_month: month,
          cvc: body.cvc,
        }
      };

      const token = await (
        new Promise((resolve, reject) => {
          Conekta.Token.create(
            tokenParams,
            (payload) => {
              const value = get(payload, 'id', '');
              resolve(value);
            },
            (payload) => {
              const error = get(payload, 'message_to_purchaser', '') || 'Ocurrió un error, por favor recargue';
              setErrorOpen(error);
              reject(error);
            }
          );
        })
      );

      const response = await Axios
        .post(`${BASE_URL}/reservations`,
          {
            name: body.nombre,
            email: body.email,
            phone: body.numero,
            checkIn: body.llegada,
            checkOut: body.salida,
            cost: totalPrice * totalDays,
            adults: totalAdults,
            children: totalChildren,
            hotel: selectedHotel,
            rooms: order.map(item => item.id),
            token,
          }
        );

      const error = get(response, 'data.data.error', false);
      if (error) {
        const message = get(response, 'data.data.message', '');
        setErrorOpen(message || messages.event.error);
        return;
      }

      // setBody({ ...initialBody });
      setSuccessOpen(messages.reservationComplete);
      setActiveStep(3);
    } catch (e) {
      if (typeof e === 'string') {
        setErrorOpen(e);
      } else {
        setErrorOpen(get(e, 'data.message', '') || messages.event.error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSetBody = (key, value) => {
    if (key === 'llegada' || key === 'salida') {
      setOrder([]);
      setBaseRooms(_rooms => _rooms.map(item => {
        const newItem = { ...item };
        delete newItem.price;
        delete newItem.isValidAllDays;
        delete newItem.isValidAvailability;
        return newItem;
      }))
    }
    setBody(_body => ({
      ..._body,
      ...(key === 'hotel' ? {room: null, adultos: '', ninios: '', llegada: '', salida: ''} : {}),
      ...(key === 'room' ? {adultos: value.adults, ninios: value.children} : {}),
      ...(key === 'llegada' ? {salida: ''} : {}),
      [key]: value,
    }));

    if (key === 'room') {
      setSelectedRoom(value || null);
    }
  }

  const handleClose = () => {
    setSuccessOpen('');
    setErrorOpen('');
  };

  const handleSetSelectedHotel = hotel => {
    setActiveStep(1);
    setSelectedHotel(hotel);
  }

  const handleAddRoom = room => {
    setOrder(_order => [..._order, room]);
  }

  const handleDeleteOrder = index => {
    setOrder(_order => {
      const newOrder = [..._order];
      newOrder.splice(index, 1);
      return newOrder;
    });
  }

  const getOrderResume = (disabled, resume) => {
    let totalDays = 0;
    let auxDate = moment(body.llegada);
    do {
      totalDays += 1;
      auxDate = moment(body.llegada).add(totalDays, 'days');
    } while (moment(auxDate).isBefore(body.salida));
    const [totalPrice, totalAdults, totalChildren] = order.reduce(
      (total, item) => {
        const [price, adults, children] = total;
        return [
          price + item.price,
          adults + item.adults,
          children + item.children,
        ];
      },
      [0, 0, 0]
    );

    return (
      <ResumeOrder>
        <div className="total-pay">
          <div>{resume ? 'Total' : messages.totalToPay}</div>
          <div>
            <div className="align-right">
              {`${Numeral(totalPrice * totalDays).format('$ 0,0.00')} MXN`}
            </div>
            <div className="small-label">{messages.taxesIncluded}</div>
          </div>
        </div>
        <div className="detail">
          {`${order.length} ${messages.roomLabel}, ${totalAdults} ${messages.roomsReservation.adults} (max), ${totalChildren} ${messages.roomsReservation.children} (max)`}
        </div>
        <div className="detail">
          {`${totalDays} ${messages.nights}`}
        </div>
        {!disabled && (
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={handleConfirmReservation}
          >
            {messages.book}
          </Button>
        )}
      </ResumeOrder>
    );
  };

  const handleConfirmReservation = () => {
    setActiveStep(2);
  }

  const handleChangeStep = newStep => {
    if (newStep < activeStep) {
      setActiveStep(newStep);
      if (newStep === 0) {
        setOrder([]);
        setBaseRooms([]);
        setSelectedHotel(null);
      }
    }
  };

  // const disabled = !avisoPrivacidadChecked || !termsChecked || !avisoLegalChecked;
  const disabled =
    !avisoPrivacidadChecked ||
    !termsChecked ||
    !body.llegada ||
    !body.salida ||
    !body.nombre ||
    !body.numero ||
    !body.email ||
    !body.card ||
    !body.nameOnCard ||
    !body.expiration ||
    !body.cvc;

  /*global Conekta*/
  /*eslint no-undef: "error"*/
  // console.log('Conekta', Conekta);

  return (
    <Container>
      <Helmet>
        <script type="text/javascript" src="/conekta.js"></script>
      </Helmet>
      <Header />
      <div className="content-reservation">
        {activeStep < 3 && (
          <div className="steps-container">
            <Stepper activeStep={activeStep} alternativeLabel={isResponsive}>
              {steps.map((label, index) => {
                return (
                  <Step key={label} completed={activeStep > index} onClick={() => handleChangeStep(index)}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        )}
        {activeStep === 0 && (
          <React.Fragment>
            <h2>{messages.steps.selectAnHotel}</h2>
            <div className="card-list">
              <Card
                isButton
                onHotelSelected={() => handleSetSelectedHotel('la-paz')}
                hotel="la-paz"
                data={data.places}
              />
              <Card
                isButton
                onHotelSelected={() => handleSetSelectedHotel('guadalajara')}
                hotel="guadalajara"
                data={data.places}
              />
              <Card
                isButton
                onHotelSelected={() => handleSetSelectedHotel('el-diez')}
                hotel="el-diez"
                data={data.places}
              />
              <Card
                isButton
                onHotelSelected={() => handleSetSelectedHotel('navolato')}
                hotel="navolato"
                data={data.places}
              />
              <Card
                isButton
                onHotelSelected={() => handleSetSelectedHotel('kumate')}
                hotel="kumate"
                data={data.places}
              />
              <Card
                isButton
                onHotelSelected={() => handleSetSelectedHotel('bellavista')}
                hotel="bellavista"
                data={data.places}
              />
            </div>
          </React.Fragment>
        )}
        {activeStep === 1 && (
          <React.Fragment>
            <div className="dates-rooms-section">
              <div className="left-section">
                <h2>{messages.steps.selectRooms}</h2>
                <div className="room-list">
                  {baseRooms.map(room => (
                    <RoomCard key={room.id} onRoomSelected={() => handleAddRoom(room)} room={room} />
                  ))}
                </div>
              </div>
              <div className="right-section">
                <div className="hotel-name">{selectedHotel && hotelsMap[selectedHotel]}</div>
                <div className="date-inputs">
                  <div>
                    <h5>{messages.reservations.arrival}</h5>
                    <Input
                      placeholder={messages.reservations.arrival}
                      type="date"
                      value={body.llegada}
                      onChange={e => handleSetBody('llegada', e.target.value)}
                      min={minDate}
                      max={maxDateArrival}
                    />
                  </div>
                  <div>
                    <h5>{messages.reservations.departure}</h5>
                    <Input
                      placeholder={messages.reservations.departure}
                      type="date"
                      value={body.salida}
                      onChange={e => handleSetBody('salida', e.target.value)}
                      min={departureMinDate}
                      max={departureMaxDate}
                    />
                  </div>
                </div>
                {order.length > 0 ? (
                  order.map((room, index) => <OrderItem key={index} room={room} onDelete={() => handleDeleteOrder(index)} />)
                ) : (
                  <Button variant="contained" color="secondary" fullWidth onClick={handleCheckAvailability}>
                    {messages.verifyAvailaility}
                  </Button>
                )}
                {order.length > 0 && getOrderResume()}
              </div>
            </div>
          </React.Fragment>
        )}
        {activeStep === 2 && (
          <React.Fragment>
            <div className="dates-rooms-section">
              <div className="left-section">
                <div className="general-data">
                  <h2>{messages.generalInfo}</h2>
                  <div className="form">
                    <Input
                      placeholder={messages.reservations.fullName}
                      value={body.nombre}
                      onChange={e => handleSetBody('nombre', e.target.value)}
                      className="full-width"
                    />
                    <Input
                      placeholder={messages.reservations.phone}
                      type="phone"
                      value={body.numero}
                      onChange={e => handleSetBody('numero', e.target.value)}
                    />
                    <Input
                      placeholder={messages.reservations.email}
                      type="email"
                      value={body.email}
                      onChange={e => handleSetBody('email', e.target.value)}
                    />
                  </div>
                  <h2>{messages.paymentInfo}</h2>
                  <div className="form">
                    <Input
                      placeholder={messages.nameOnCard}
                      type="text"
                      value={body.nameOnCard}
                      onChange={e => handleSetBody('nameOnCard', e.target.value)}
                      className="full-width"
                    />
                    <div>
                      <MaskedInput
                        mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                        placeholder={messages.cardNumber}
                        showMask={false}
                        className="masked-input"
                        guide={false}
                        onChange={e => handleSetBody('card', e.target.value)}
                        value={body.card}
                        onFocus={() => setCardErrors(_errors => ({ ..._errors, number: false }))}
                      />
                      {cardErrors.number && (
                        <div className="error-message">{messages.cardNumberError}</div>
                      )}
                    </div>
                    <div className="quarter-div">
                      <MaskedInput
                        mask={[/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/]}
                        placeholder="MM/AA"
                        showMask={false}
                        className="masked-input"
                        guide={false}
                        onChange={e => handleSetBody('expiration', e.target.value)}
                        value={body.expiration}
                        onFocus={() => setCardErrors(_errors => ({ ..._errors, expiration: false }))}
                      />
                      {cardErrors.expiration && (
                        <div className="error-message">{messages.invalidField}</div>
                      )}
                    </div>
                    <div className="quarter-div">
                      <MaskedInput
                        mask={[/\d/, /\d/, /\d/]}
                        placeholder="CVC"
                        showMask={false}
                        className="masked-input"
                        guide={false}
                        onChange={e => handleSetBody('cvc', e.target.value)}
                        value={body.cvc}
                        onFocus={() => setCardErrors(_errors => ({ ..._errors, cvc: false }))}
                      />
                      {cardErrors.cvc && (
                        <div className="error-message">{messages.invalidField}</div>
                      )}
                    </div>
                  </div>
                  <div className="input-checkboxes">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={avisoPrivacidadChecked}
                          onChange={e => setAvisoPrivacidadChecked(e.target.checked)}
                          name="aviso-privacidad-check"
                          color="primary"
                        />
                      }
                      label={(
                        <div>
                          {messages.acceptPrivacy}
                          <Link to="/aviso-privacidad" target="_blank">{messages.noticePrivacy}</Link>
                        </div>
                      )}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={termsChecked}
                          onChange={e => setTermsChecked(e.target.checked)}
                          name="terminos-check"
                          color="primary"
                        />
                      }
                      label={(
                        <div>
                          {messages.acceptPrivacyPlural}
                          <Link to="/terminos-condiciones" target="_blank">{messages.termAndConditions}</Link>
                        </div>
                      )}
                    />
                  </div>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleRequestReservation}
                    disabled={disabled || loading}
                  >
                    {messages.book}
                  </Button>
                </div>
              </div>
              <div className="right-section">
                <div className="hotel-name">{selectedHotel && hotelsMap[selectedHotel]}</div>
                <div className="date-inputs">
                  <div>
                    <h5>{messages.reservations.arrival}</h5>
                    <Input
                      placeholder={messages.reservations.arrival}
                      type="date"
                      value={body.llegada}
                      onChange={e => handleSetBody('llegada', e.target.value)}
                      min={minDate}
                      disabled
                    />
                  </div>
                  <div>
                    <h5>{messages.reservations.departure}</h5>
                    <Input
                      placeholder={messages.reservations.departure}
                      type="date"
                      value={body.salida}
                      onChange={e => handleSetBody('salida', e.target.value)}
                      min={departureMinDate}
                      disabled
                    />
                  </div>
                </div>
                {order.map((room, index) => <OrderItem key={index} room={room} onDelete={() => handleDeleteOrder(index)} disabled />)}
                {order.length > 0 && getOrderResume(true)}
              </div>
            </div>
          </React.Fragment>
        )}
        {activeStep === 3 && (
          <div className="reservation-detail">
            <h1>{messages.thanksForBooking}</h1>
            <div className="right-section">
              <div className="hotel-name">{selectedHotel && hotelsMap[selectedHotel]}</div>
              <div className="date-inputs">
                <div>
                  <h5>{messages.reservations.arrival}</h5>
                  <div>{body.llegada}</div>
                </div>
                <div>
                  <h5>{messages.reservations.departure}</h5>
                  <div>{body.salida}</div>
                </div>
              </div>
              <div className="date-inputs">
                <div>
                  <h5>{messages.reservations.fullName}</h5>
                  <div>{body.nombre}</div>
                </div>
                <div>
                  <h5>{messages.reservations.phone}</h5>
                  <div>{body.numero}</div>
                </div>
              </div>
              <div className="date-inputs">
                <div>
                  <h5>{messages.reservations.email}</h5>
                  <div>{body.email}</div>
                </div>
              </div>
              {order.map((room, index) => <OrderItem key={index} room={room} onDelete={() => handleDeleteOrder(index)} disabled />)}
              {order.length > 0 && getOrderResume(true, true)}
            </div>
          </div>
        )}
      </div>
      <Footer />
      <Snackbar open={Boolean(successOpen)} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successOpen}
        </Alert>
      </Snackbar>
      <Snackbar open={Boolean(errorOpen)} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorOpen}
        </Alert>
      </Snackbar>
    </Container>
  );
}

// {false && (<div className="block">
//   <h2>{messages.reservations.reservation}</h2>
//   <div className="inputs-list">
//     <Select
//       placeholder={messages.reservations.hotel}
//       options={options}
//       value={body.hotel}
//       onChange={value => handleSetBody('hotel', value)}
//     />
//     <div className="couple-inputs">
//       <Input
//         placeholder={messages.reservations.arrival}
//         type="date"
//         value={body.llegada}
//         onChange={e => handleSetBody('llegada', e.target.value)}
//         min={minDate}
//       />
//       <Input
//         placeholder={messages.reservations.departure}
//         type="date"
//         value={body.salida}
//         onChange={e => handleSetBody('salida', e.target.value)}
//         min={departureMinDate}
//       />
//     </div>
//     <Select
//       placeholder={messages.reservations.room}
//       options={rooms}
//       value={body.room}
//       onChange={value => handleSetBody('room', value)}
//     />
//     <Input
//       placeholder={messages.reservations.adults}
//       type="number"
//       value={body.adultos}
//       onChange={e => handleSetBody('adultos', e.target.value)}
//       disabled={!selectedRoom}
//       max={get(selectedRoom, 'adults', 0)}
//       min={0}
//     />
//     <Input
//       placeholder={messages.reservations.children}
//       type="number"
//       value={body.ninios}
//       onChange={e => handleSetBody('ninios', e.target.value)}
//       disabled={!selectedRoom}
//       max={get(selectedRoom, 'children', 0)}
//       min={0}
//     />
//     <Input
//       placeholder={messages.reservations.promotionCode}
//       value={body.codigo}
//       onChange={e => handleSetBody('codigo', e.target.value)}
//     />
//     <Input
//       placeholder={messages.reservations.fullName}
//       value={body.nombre}
//       onChange={e => handleSetBody('nombre', e.target.value)}
//     />
//     <Input
//       placeholder={messages.reservations.phone}
//       type="phone"
//       value={body.numero}
//       onChange={e => handleSetBody('numero', e.target.value)}
//     />
//     <div className="input-checkboxes">
//       <Input
//         placeholder={messages.reservations.email}
//         type="email"
//         value={body.email}
//         onChange={e => handleSetBody('email', e.target.value)}
//       />
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={avisoPrivacidadChecked}
//             onChange={e => setAvisoPrivacidadChecked(e.target.checked)}
//             name="aviso-privacidad-check"
//             color="primary"
//           />
//         }
//         label={(
//           <div>
//             {messages.acceptPrivacy}
//             <Link to="/aviso-privacidad" target="_blank">{messages.noticePrivacy}</Link>
//           </div>
//         )}
//       />
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={termsChecked}
//             onChange={e => setTermsChecked(e.target.checked)}
//             name="terminos-check"
//             color="primary"
//           />
//         }
//         label={(
//           <div>
//             {messages.acceptPrivacyPlural}
//             <Link to="/terminos-condiciones" target="_blank">{messages.termAndConditions}</Link>
//           </div>
//         )}
//       />
//     {/*<FormControlLabel
//         control={
//           <Checkbox
//             checked={avisoLegalChecked}
//             onChange={e => setAvisoLegalChecked(e.target.checked)}
//             name="aviso-legal-check"
//             color="primary"
//           />
//         }
//         label={(
//           <div>
//             {messages.acceptPrivacy}
//             <Link to="/aviso-legal" target="_blank">{messages.noticeLegal}</Link>
//           </div>
//         )}
//       />*/}
//     </div>
//   </div>
//   <div className="brief">
//     <div className="cost">{nightCostFormatted} / {messages.reservations.night}</div>
//     <button onClick={handleRequestReservation} disabled={disabled}>
//       {messages.reservations.book}
//     </button>
//   </div>
// </div>)}

const spanishMessages = {
  restaurants: 'Restaurantes',
  events: 'Eventos',
  contact: 'Contacto',
  reservation: 'Reservar',
  welcome: 'Bienvenido a Hoteles Maioris',
  welcomeMessage: 'Reservar en línea es cómodo y seguro',
  hotel: 'Hotel',
  arrive: 'Llegada',
  exit: 'Salida',
  adults: 'Adultos',
  children: 'Niños',
  code: 'Código de promoción',
  book: 'Reservar',
  ourHotels: 'Nuestros hoteles',
  hotelsDescription: 'Somos un grupo enfocado en brindar la mejor atención, servicios y experiencias a cada uno de nuestros huéspedes, tanto de viaje de negocios como familiar. Diseñados para vivir una estadía perfecta de descanso, comodidad y hospitalidad, con acceso a restaurante Signus, salas de reuniones totalmente acondicionadas, y con estacionamiento amplio y seguro. Más de 20 años de experiencia nos respaldan.',
  seeMore: 'Ver más',
  ourPromotions: 'Conoce nuestras promociones',
  promotionsDesc: 'En hoteles Maioris, consentirte es primero',
  seePromotions: 'Ver promociones',
  restrictions: 'Aplica restricciones',
  ourPresence: 'Nuestra Presencia',
  presenceDesc: 'Hoteles Maioris se encuentra presente en Culiacán, Navolato, Guadalajara y La Paz.',
  hotels: 'Hoteles',
  hotelPolitics: 'Políticas del hotel',
  noticePrivacy: 'Aviso de privacidad',
  noticePrivacyLink: 'https://hotelesmaioris.com/aviso-privacidad-hoteles-maioris.pdf',
  noticeLegal: 'Aviso legal',
  noticeLegalLink: 'https://hotelesmaioris.com/aviso-legal.pdf',
  termConditions: 'Términos y condiciones',
  termConditionsLink: 'https://hotelesmaioris.com/terminos-condiciones.pdf',
  termAndConditions: 'Términos y condiciones',
  followUs: 'Síguenos en',
  laPaz: {
    brief1: 'Ubicado sobre la Carretera Transpeninsular, a solo 4 km del Aeropuerto Internacional Manuel Márquez de León, a 8 km de las principales plazas comerciales y a 9 km del centro de la ciudad, Hotel Maioris La Paz es una excelente opción para familias y huéspedes de negocios en búsqueda de hospedaje económico.',
    brief2: 'Moderna decoración y un ambiente amigable de hotel familiar harán de tu estancia en Hotel Maioris La Paz una experiencia confortable de principio a fin. Las instalaciones ofrecen todo lo que necesita y un poco más, para disfrutar al máximo tu estancia en la bella capital del estado.',
    whatToDo: '¿Qué hacer en La Paz?',
    shortWhatToDo: 'La Bahía de La Paz es reconocida por la tranquilidad de sus aguas, la espectacularidad de sus atardeceres y la amplia gama de tonos turquesa que se difuminan en sus playas. La ciudad se caracteriza por la tranquilidad y calma con que se vive, un lugar que hace gala de su nombre, “La Paz”, y que se ha convertido a nivel mundial en un reconocido destino para el descanso y el retiro. La hospitalidad y calidez de su gente, hacen de este puerto un lugar confortable y apacible.',
    fullDescription: [
      'La Paz es la capital del estado de Baja California Sur, un bello lugar lleno de contrastes en sus paisajes, el desierto decorado naturalmente en cactáceas y sus hermosas playas separan la tierra del mar con un sesgo de color que impacta la pupila, creando un panorama de postal que usted disfrutará.',
      'La Bahía de La Paz es reconocida por la tranquilidad de sus aguas, la espectacularidad de sus atardeceres y la amplia gama de tonos turquesa que se difuminan en sus playas. La ciudad se caracteriza por la tranquilidad y calma con qué se vive, un lugar que hace gala de su nombre, “La Paz”, y que se ha convertido a nivel mundial en un reconocido destino para el descanso y el retiro. La hospitalidad y calidez de su gente, hacen de este puerto un lugar confortable y apacible.',
      'Por ser el estado con más territorio costero del país, 2,230 kilómetros de litorales, las actividades relacionadas con el mar, como la pesca deportiva, el buceo, kayac y esnórquel, junto con otros deportes acuáticos, son predominantes y grandes atractivos de la zona que no puedes perderte. Además de sus paradisíacas playas de aguas mansas y arena color crema puedes aprovechar para descansar o para entretenerte.',
      'La riqueza histórica y cultural con la que cuenta esta ciudad son elementos que multiplican enormemente los motivos para visitarla; el Malecón, donde arribó Hernán Cortés en 1535, es uno de los lugares preferidos de turistas y locales, presentando la bahía como un majestuoso y armonioso escenario rodeado de diversión, restaurantes, bellos paisajes y confort.',
      'La ciudad cuenta con todos los servicios para satisfacer las necesidades del turista más exigente: vuelos, hoteles, renta de autos, actividades y vida nocturna son elementos que reúnen las cualidades para que tu estancia en La Paz, ya sea de negocios o placer, sea ideal.',
    ],
  },
  guadalajara: {
    brief1: 'Hotel Maioris se encuentra en Guadalajara, a 17 km del Aeropuerto Internacional de Guadalajara, a 11 km del Templo Expiatorio y ofrece alojamiento con restaurante, aparcamiento privado gratuito y bar.',
    brief2: 'Cuenta con recepción 24 horas, servicio de habitaciones y WiFi gratuito en todas las instalaciones. El establecimiento se encuentra a 17 km del estadio de Jalisco y a 28 km del zoológico de Guadalajara.',
    whatToDo: '¿Qué hacer en Guadalajara?',
    shortWhatToDo: 'Guadalajara es la Perla de Occidente de México, reconocido destino a nivel mundial por ser la capital del Tequila y del Mariachi. Esta ciudad, capital del estado de Jalisco, cuenta con un inmenso acervo histórico y cultural, así como un extenso número de monumentales construcciones reconocidas por su espectacular arquitectura, principalmente de las corrientes Neoclásica y Barroca.',
    fullDescription: [
      'Guadalajara es la Perla de Occidente de México, reconocido destino a nivel mundial por ser la capital del Tequila y del Mariachi. Esta ciudad, capital del estado de Jalisco, cuenta con un inmenso acervo histórico y cultural, así como un extenso número de monumentales construcciones reconocidas por su espectacular arquitectura, principalmente de las corrientes Neoclásica y Barroca.',
      'Guadalajara es la segunda ciudad más grande del país y una importante arteria de la economía mexicana, debido a la cantidad de actividades económicas que se desarrollan en ella, entre las que destaca el turismo, por ofrecer una vasta gama de opciones y actividades a los visitantes, con los mejores hoteles, actividades culturales y recreativas, así como su intensa vida nocturna y de entretenimiento.',
      'La invariable cantidad de lugares por visitar en Guadalajara le dan una riqueza única, como son sus museos, galerías, balnearios, parques, reservas naturales, sitios arqueológicos, esculturas, fuentes, glorietas, monumentos, murales, plazas, jardines y andadores, los cuales cultivan y cautivan a quien visita la capital tapatía.',
      'La riqueza que ofrece Guadalajara es la tradición y la modernidad. Embajadora de la imagen de México, es una de las ciudades más representativas del país y toda una referencia histórica, pero también es una urbe desarrollada. Sus eficientes y actualizados servicios públicos garantizan una estancia cómoda, así como sus modernos transportes, hacen posible un movimiento ágil en la capital jalisciense.',
      'Otro de los importantes atractivos de Guadalajara, es la cercanía que tiene con otros importantes puntos de la geografía de Jalisco, como lo son Tonalá, Zapopan, y sobre todo Puerto Vallarta y Tequila, estos dos últimos trascendentales destinos de captación turística.',
      'La gastronomía de la ciudad destaca por sus tortas ahogadas, por el tradicional tequila y por su birria de res, deliciosos motivos que incrementan “el sabor” de esta ciudad cosmopolita llena de atractivos.',
    ],
  },
  elDiez: {
    brief1: 'El Hotel Maioris El Diez Culiacán ofrece cómodas habitaciones en Culiacán, a 10 km del centro comercial Fórum Culiacán y a 11 km del estadio Banorte.',
    brief2: 'El aeropuerto más cercano es el aeropuerto internacional de Culiacán, situado a 3 km del hotel.',
    whatToDo: '¿Qué hacer en Culiacán?',
    shortWhatToDo: 'La ciudad capital del estado ofrece toda una serie de posibilidades de entretenimiento, diversión y recreación al turista que la visita. Localizada en el centro de Sinaloa, Culiacán es un paraíso para los amantes del turismo rural y para quienes gustan de los deportes extremos, montañismo, rappel y toda clase de actividades al aire libre.',
    fullDescription: [
      'La ciudad capital del estado ofrece toda una serie de posibilidades de entretenimiento, diversión y recreación al turista que la visita. Localizada en el centro de Sinaloa, Culiacán es un paraíso para los amantes del turismo rural y para quienes gustan de los deportes extremos, montañismo, rapen y toda la clase de actividades al aire libre.',
      'En Culiacán hallará sitios icónicos que deslumbran por si arquitectura, tales como la Catedral de Nuestra Señora del Rosario, un imponente construcción del siglo XIX localizada a un costado de la Plaza Álvaro Obregón; las Fuentes Danzarinas, con su música, colores y movimientos, maravillan a sus espectadores, mientras que otros espacios dedicados a la preservación de la naturaleza, como la Granja de Cocodrilos, enseñan a sus visitantes sobre el cuidado de la fauna local.',
      'Al visitar Culiacán no deje de hacer un recorrido turístico a bordo del Turibús. Disponible de martes a domingo, este tour es guiado cuenta con la explicación de un guía bien capacitado. Entre los sitios que visitará se encuentra el Jardín Botánico, la Casa Museo del Chino Billetero, el Parque Zoológico y el templo de Nuestra Sra. De Guadalupe.',
      'Parque las Riberas es uno de los principales parques recreativos y de esparcimiento en la ciudad. Aquí hay tiroteas, una ciclopista, eurobongie, una zona de reptiles y toda clase de atracciones para toda la familia, disponibles de martes a domingo.',
      'Culiacán cuenta con hermosas playas como el Conchal, el Médano Blanco, el Vigía, El Robalar,  Las Arenitas y las playas de Ponce son muy populares entre surfistas y naturalistas que gustan de la observación de aves y fauna marina.',
      'No te quedes sin visitar el parque EME son 52 hectáreas de diversión y sano entrenamiento. Este parque acuático ofrece canchas de tenis, basquetbol, albercas, toboganes, jardines y toda una gama de atracciones para toda la familia, lo que lo ha vuelto uno de los sitios preferidos por los locales.',
    ],
  },
  navolato: {
    brief1: 'Culiacán se encuentra a 13 km de Hotel Maioris Navolato. El aeropuerto más cercano es el aeropuerto internacional de Culiacán, situado a 15 km del hotel.',
    brief2: 'El establecimiento cuenta con recepción 24 horas, servicio de habitaciones y WiFi gratuita. El hotel cuenta con jardín y piscina al aire libre.',
    whatToDo: '¿Qué hacer en Navolato?',
    shortWhatToDo: 'Navolato es el municipio más joven del estado mexicano de Sinaloa; su nombre, que significa Donde Hay Tunas y Nopales, proviene del idioma mayo “navo”, del azteca y del cahíta-náhuatl. Por muchos años, la actividad económica más importante en Navolato fue la agricultura, donde el principal cultivo es el de la caña. Hoy en día, debido al cierre de los ingenios azucareros ha ido en declive, dando impulso a la industria turística.',
    fullDescription: [
      'Navolato es el municipio más joven de Sinaloa, que significa Donde Hay Tunas y Nopales, proviene del idioma mayo “navo”, del azteca y del ahíta-náhuatl. Por muchos años, la actividad económica más importante en Navolato fue la agricultura, donde el principal cultivo es el de la caña. Hoy en día, debido al cierre de los ingenios azucareros ha ido en declive, dando impulso a la industria turística.',
      'Con una temperatura media anual de 24º C y hermosas costas de aguas tranquilas, Navolato se perfila como un destino especial para el turismo de playa. La pintoresca Bahía de Altata, a 65km de la capital sinaloense, y su playa son zonas dedicadas a la pesca de camarón y ostión pero también es un área natural protegida al ser hogar de manglares, arrecifes e increíble fauna nativa.',
      'Las playas de Navolato son perfectas para un día de sol y arena. Playa El Tambor es favorita para acampar y disfrutar de divertidas y emocionantes actividades acuáticas; localidades como El Tetuán son privilegiadas por su ubicación en las costas sinaloenses. Además de playas existen atractivos arquitectónicos, tales como:',
      'La Parroquia de San Francisco de Asís',
      'Construcción de estilo neoclásico que destaca por pórtico con arcos de medio punto. El día de San Francisco se celebra en grande con eventos musicales, bailes y por supuesto una misa oficiada en la parroquia.',
      'Capilla de San José',
      'Ubicada en las faldas del llamado cerro de la nevería. Es una construcción de estilo ecléctico que en su interior resguarda la imagen de San José, de Santa Teresa de Ávila y de la Inmaculada Concepción, estas últimas dos traídas desde Europa.',
      'Plazuela Vicente Guerrero',
      'Un bonito kiosko adorna la plaza central de Navolato, Sinaloa. Ubicada frente a la parroquia de San Francisco de Asís, es el lugar favorito de los locales quienes disfrutan aquí de la venta de artesanías y gastronomía típica.',
    ],
  },
  kumate: {
    brief1: 'El Hotel Maioris sucursal Jesús Kumate está situado en Culiacán, a 8 km del centro comercial Fórum Culiacán y ofrece habitaciones con aire acondicionado y un fresco y agradable jardín.',
    brief2: 'El estadio Banorte está a 9 km. El aeropuerto más cercano es el Aeropuerto Internacional de Culiacán, situado a 2 km del hotel.',
    whatToDo: '¿Qué hacer en Culiacán?',
    shortWhatToDo: 'La ciudad capital del estado ofrece toda una serie de posibilidades de entretenimiento, diversión y recreación al turista que la visita. Localizada en el centro de Sinaloa, Culiacán es un paraíso para los amantes del turismo rural y para quienes gustan de los deportes extremos, montañismo, rappel y toda clase de actividades al aire libre.',
    fullDescription: [
      'La ciudad capital del estado ofrece toda una serie de posibilidades de entretenimiento, diversión y recreación al turista que la visita. Localizada en el centro de Sinaloa, Culiacán es un paraíso para los amantes del turismo rural y para quienes gustan de los deportes extremos, montañismo, rapen y toda la clase de actividades al aire libre.',
      'En Culiacán hallará sitios icónicos que deslumbran por si arquitectura, tales como la Catedral de Nuestra Señora del Rosario, un imponente construcción del siglo XIX localizada a un costado de la Plaza Álvaro Obregón; las Fuentes Danzarinas, con su música, colores y movimientos, maravillan a sus espectadores, mientras que otros espacios dedicados a la preservación de la naturaleza, como la Granja de Cocodrilos, enseñan a sus visitantes sobre el cuidado de la fauna local.',
      'Al visitar Culiacán no deje de hacer un recorrido turístico a bordo del Turibús. Disponible de martes a domingo, este tour es guiado cuenta con la explicación de un guía bien capacitado. Entre los sitios que visitará se encuentra el Jardín Botánico, la Casa Museo del Chino Billetero, el Parque Zoológico y el templo de Nuestra Sra. De Guadalupe.',
      'Parque las Riberas es uno de los principales parques recreativos y de esparcimiento en la ciudad. Aquí hay tiroteas, una ciclopista, eurobongie, una zona de reptiles y toda clase de atracciones para toda la familia, disponibles de martes a domingo.',
      'Culiacán cuenta con hermosas playas como el Conchal, el Médano Blanco, el Vigía, El Robalar,  Las Arenitas y las playas de Ponce son muy populares entre surfistas y naturalistas que gustan de la observación de aves y fauna marina.',
      'No te quedes sin visitar el parque EME son 52 hectáreas de diversión y sano entrenamiento. Este parque acuático ofrece canchas de tenis, basquetbol, albercas, toboganes, jardines y toda una gama de atracciones para toda la familia, lo que lo ha vuelto uno de los sitios preferidos por los locales.',
    ],
  },
  bellavista: {
    brief1: 'Hotel Maioris Bellavista ofrece cómodas habitaciones de lujo totalmente acondicionadas bajo un concepto ejecutivo además de contar con alberca para hacer de tu estadía lo más agradable posible.',
    brief2: 'El centro comercial Forum Culiacán se encuentra a 9 km del Hotel Maioris Bellavista. El aeropuerto internacional de Culiacán es el más cercano y está a 11 km del alojamiento.',
    whatToDo: '¿Qué hacer en Culiacán?',
    shortWhatToDo: 'La ciudad capital del estado ofrece toda una serie de posibilidades de entretenimiento, diversión y recreación al turista que la visita. Localizada en el centro de Sinaloa, Culiacán es un paraíso para los amantes del turismo rural y para quienes gustan de los deportes extremos, montañismo, rappel y toda clase de actividades al aire libre.',
    fullDescription: [
      'La ciudad capital del estado ofrece toda una serie de posibilidades de entretenimiento, diversión y recreación al turista que la visita. Localizada en el centro de Sinaloa, Culiacán es un paraíso para los amantes del turismo rural y para quienes gustan de los deportes extremos, montañismo, rapen y toda la clase de actividades al aire libre.',
      'En Culiacán hallará sitios icónicos que deslumbran por si arquitectura, tales como la Catedral de Nuestra Señora del Rosario, un imponente construcción del siglo XIX localizada a un costado de la Plaza Álvaro Obregón; las Fuentes Danzarinas, con su música, colores y movimientos, maravillan a sus espectadores, mientras que otros espacios dedicados a la preservación de la naturaleza, como la Granja de Cocodrilos, enseñan a sus visitantes sobre el cuidado de la fauna local.',
      'Al visitar Culiacán no deje de hacer un recorrido turístico a bordo del Turibús. Disponible de martes a domingo, este tour es guiado cuenta con la explicación de un guía bien capacitado. Entre los sitios que visitará se encuentra el Jardín Botánico, la Casa Museo del Chino Billetero, el Parque Zoológico y el templo de Nuestra Sra. De Guadalupe.',
      'Parque las Riberas es uno de los principales parques recreativos y de esparcimiento en la ciudad. Aquí hay tiroteas, una ciclopista, eurobongie, una zona de reptiles y toda clase de atracciones para toda la familia, disponibles de martes a domingo.',
      'Culiacán cuenta con hermosas playas como el Conchal, el Médano Blanco, el Vigía, El Robalar,  Las Arenitas y las playas de Ponce son muy populares entre surfistas y naturalistas que gustan de la observación de aves y fauna marina.',
      'No te quedes sin visitar el parque EME son 52 hectáreas de diversión y sano entrenamiento. Este parque acuático ofrece canchas de tenis, basquetbol, albercas, toboganes, jardines y toda una gama de atracciones para toda la familia, lo que lo ha vuelto uno de los sitios preferidos por los locales.',
    ],
  },
  services: {
    services: 'Servicios',
    rooms: 'Cómodas habitaciones',
    boardRooms: 'Sala de juntas',
    wheelChair: 'Acceso a sillas de rueda',
    fireSafety: 'Seguridad contra incendios',
    bilingual: 'Personal bilingüe',
    security24: 'Seguridad 24 horas',
    hygiene: 'Amenidades de aseo personal',
    computer: 'Acceso a computadora en el lobby',
    frigobar: 'Frigobar',
    alberca: 'Alberca',
    wifi: 'WiFi',
    restaurant: 'Restaurante',
    estacionamiento: 'Estacionamiento',
    extinguidor: 'Seguridad contra incendios',
    sillaRuedas: 'Acceso para silla de ruedas',
    salaJuntas: 'Sala de juntas',
    bilingue: 'Personal bilingüe',
    computadora: 'Acceso a computadora en el lobby',
    aire: 'Aire acondicionado',
    servicio: 'Servicio a la habitación',
    tv: 'TV por cable',
    jardin: 'Jardines',
  },
  rooms: {
    rooms: 'Habitaciones',
    adults: 'Adultos',
    kingSize: 'Cama King size',
    children: 'Niños (menores de 12 años)',
    matrimonialSize: 'Camas matrimoniales',
    individual: 'Camas individual',
    queenSize: 'Camas queen size',
  },
  location: 'Ubicación',
  knowMoreDestinations: 'Conoce más destinos',
  moreServices: 'Más servicios',
  covid: {
    protocols: 'Protocolos de prevención e higiene',
    description: 'La salud y seguridad de nuestros huéspedes y colaboradores son nuestra prioridad. Se requiere usar cubrebocas en todas las áreas públicas.',
    hands: 'Lavar tus manos cada vez que sea necesario',
    mask: 'Uso obligatorio de cubrebocas en todas las áreas públicas',
    distance: 'Mantener 1.5 metros de distancia con las personas a su alrededor',
    areas: 'Sanitización constante de áreas en uso',
    checkin: 'Filtro sanitario al hacer check in',
    luggage: 'Desinfección de maletas',
    shoes: 'Desinfección de calzado',
    measures: 'Conoce las medidas sanitarias',
  },
  watch: 'Ver',
  restaurant: {
    laPaz: 'Restaurante La Paz',
    guadalajara: 'Restaurante Guadalajara',
    kumate: 'Restaurante Jesús Kumate',
    elDiez: 'Restaurante El Diez',
    description: 'En Restaurantes Signus ofrecemos una amplia variedad de platillos de excelente calidad donde podrás encontrar jugosos cortes, deliciosos mariscos, platillos regionales e internacionales y desayunos tradicionales; todo esto en un ambiente cómodo y agradable. Deleita tu paladar con nuestra excelente gastronomía con sabor casero, que hacemos con el corazón especialmente para ti.',
    seeMenu: 'Ver menú',
    menus: {
      guadalajara: {
        title: 'Menú Guadalajara',
        link: 'https://hotelesmaioris.com/restaurantes/menu/menu-guadalajara-espanol.pdf',
      },
      culiacan: {
        title: 'Menú Culiacán',
        link: 'https://hotelesmaioris.com/restaurantes/menu/menu-culiacan-espanol.pdf',
      },
      'la-paz': {
        title: 'Menú La Paz',
        link: 'https://hotelesmaioris.com/restaurantes/menu/menu-la-paz-espanol.pdf',
      },
    },
    'room-service': {
      bellavista: {
        title: 'Room service Bellavista',
        link: 'https://hotelesmaioris.com/restaurantes/room-service/bellavista-espanol.pdf',
      },
      culiacan: {
        title: 'Room service Culiacán',
        link: 'https://hotelesmaioris.com/restaurantes/room-service/culiacan-espanol.pdf',
      },
      guadalajara: {
        title: 'Room service Guadalajara',
        link: 'https://hotelesmaioris.com/restaurantes/room-service/guadalajara-espanol.pdf',
      },
      'la-paz': {
        title: 'Room service La Paz',
        link: 'https://hotelesmaioris.com/restaurantes/room-service/la-paz-espanol.pdf',
      },
      navolato: {
        title: 'Room service Navolato',
        link: 'https://hotelesmaioris.com/restaurantes/room-service/navolato-espanol.pdf',
      },
    },
  },
  event: {
    success: '¡Solicitud enviada!',
    error: 'Ocurrió un error, intente de nuevo',
    viaEsperanzaDesc1: 'En Vía Esperanza eventos hay espacio suficiente para que le des vida a tu celebración, rodeado por jardines en los que puedes crear distintas atmósferas. Celebramos ese día especial con elegancia y estilo',
    viaEsperanzaDesc2: 'Nuestros salones y jardines ofrecen las instalaciones perfectas, con servicios de calidad y personal capacitado para celebrar eventos corporativos y sociales en la mejor ubicación de la ciudad',
    viaPetraDesc1: 'Somos un equipo especializado en la coordinación y realización de eventos sociales y empresariales, ofreciendo un servicio integral que facilita al cliente la organización de su celebración, rodeado de bellas instalaciones especiales para que las transformes a tu gusto para ese día especial',
    viaPetraDesc2: 'Vía Petra te ofrece la posibilidad de hospedarte en el mismo lugar. Situado a un costado de Hotel Maioris Bellavista, le da la posibilidad a tus invitados de al terminar el evento, en cuestión de pasos, descansar en habitaciones cómodas, modernas y seguras',
    beachClubDesc1: 'Contamos con un equipo de expertos que te acompañan paso a paso hasta tu gran día. Con un hermoso Jardín y una palapa acondicionada para hacer de tu evento una hermosa e inolvidable experiencia. Vive el mejor día de tu vida con nosotros, agenda tu cita y conoce todo lo que tenemos preparado para ti',
    beachClubDesc2: 'Maioris Club Beach se encuentra situado a un costado de Hotel Maioris La Paz, para que tus invitados disfruten del evento y se hospeden en el mismo lugar',
    name: 'Nombre',
    phone: 'Número de celular',
    email: 'Correo electrónico',
    type: 'Tipo de evento',
    people: 'Cantidad de personas',
    message: 'Mensaje',
  },
  eventServices: {
    decorationsAdvisor: 'Asesor de decoraciones',
    trainedStaff: 'Personal capacitado',
    exclusiveBanquet: 'Banquete exclusivo',
    lightsAndSound: 'Luces y sonido',
    furnitureRental: 'Renta de mobiliario',
    valetParking: 'Valet parking y estacionamiento',
    surveillance: 'Vigilancia',
    innovativeDesign: 'Diseño innovador',
    eventCoordinator: 'Coordinador de eventos',
    garden: 'Jardín con palapa y alberca',
    parking: 'Estacionamiento',
    airConditioning: 'Salones con A/C',
    gardenOnly: 'Jardín',
    valet: 'Valet Parking',
  },
  contactUs: 'Contáctanos',
  contactUsDesc: 'Si deseas más información dejanos tus datos y a la brevedad nos comunicamos contigo',
  send: 'Enviar',
  contactDesc: 'Para Hoteles Maioris tus dudas, comentarios y sugerencias son siempre muy importantes. Contacta fácilmente a nuestros hoteles utilizando la siguiente información',
  reservations: {
    reservation: 'Reservación',
    book: 'Reservar',
    night: 'noche',
    hotel: 'Hotel',
    arrival: 'Llegada',
    departure: 'Salida',
    adults: 'Adultos',
    children: 'Niños',
    promotionCode: 'Código de promoción',
    fullName: 'Nombre completo',
    phone: 'Número de celular',
    email: 'Correo electrónico',
    room: 'Tipo de habitación',
  },
  incomplete: 'Información incompleta',
  reservationComplete: 'Reservación solicitada',
  politics: {
    politics: 'Políticas de privacidad',
    paragraph1: 'La seguridad es nuestra más alta prioridad para asegurar la confidencialidad de tu información personal.',
    paragraph2: 'Guardamos de manera segura en nuestro sitio toda la información. Esto incluye información personal tal como tu nombre y apellido, dirección personal y de negocios, número telefónico, dirección postal y dirección de correo electrónico.',
    paragraph3: 'La información que nos proporcionas no se comparte con terceras personas no autorizadas. Es posible que compartamos solamente lo mínimo necesario, a compañías relacionadas con el servicio de pago en línea.',
    reservationPolitics: 'Políticas de reservación',
    checkin: 'Check-in y Check-out',
    checkinDesc: 'El horario de ingreso a la habitación se fija a partir de las 3:00 p.m. y debe ser desocupada a las 12:00 p.m. del día siguiente; después de esa hora, el Hotel puede efectuar un cargo extra.',
    guarantee: 'Política de garantía',
    guaranteeDesc: 'Toda reserva deberá ser garantizada mediante su pago en cualquiera de los métodos disponibles, a nombre del Huésped. En caso de que dicha reserva no se garantice, el Hotel no estará obligado a respetar la reserva.',
    cancel: 'Política de cancelación',
    cancelDesc: 'Cualquier cambio o cancelación a su reserva deberá solicitarla marcando directamente a la recepción del destino con mínimo 72 horas de anticipación de la fecha de llegada al hotel para no generar cargos. Posterior a estas 72 horas, se realizará el cobro de una noche por cuarto reservado.',
    notArrive: 'Política de no arribo',
    notArriveDesc: 'En caso de No Arribo, se cobrará una noche de estancia.',
    extra: 'Política de persona extra',
    extraDesc: 'Aplica cargo por persona extra en la habitación. Ver tarifa al momento de checar disponibilidad.',
    children: 'Política de menores',
    childrenDesc: 'Se considera niños hasta los 11 años. Niños a partir de 12 años se cobrarán como adultos. La habitación con 2 adultos, podrá ser compartida con 2 niños menores de 12 años sin cobro adicional.',
    pets: 'Política de máscotas',
    petsDes: 'Disculpe, no se aceptan mascotas.',
    overSell: 'Política de sobreventa',
    overSellDesc: 'En caso que el Hotel no pueda brindar el servicio de hospedaje contratado con una reservación proveniente del sitio web, brindaremos adecuadas alternativas para suplir el servicio contratado, que sean por lo menos igual a los servicios contratados originalmente, y se le ofrecerá transporte gratis al nuevo Hotel. Realizaremos al huésped al menos una llamada previa a su estancia al teléfono proporcionado  comentando la situación.',
    specialPrice: 'Tarifas convenio',
    specialPriceDesc: 'Para grupos y empresas, favor de contactarse directamente a la recepción del hotel en que desea hospedarse para poder otorgarles una tarifa preferencial. ',
  },
  privacy: {
    title: 'Aviso de privacidad',
    intro: 'El presente aviso de privacidad se integra a partir de lo señalado en los artículos 8, 15, 16, 18, 36, entre otros de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP).',
    paragraphs: [
      {
        title: 'Aviso de privacidad integral',

      }
    ],
  },
  findUs: 'Estamos ubicados en',
  comments: {
    title: '¿Te has hospedado con nosotros?',
    description: 'Déjanos tus comentarios para mejorar el servicio',
    action: 'Dejar comentarios',
    moreHotels: 'Conoce nuestros destinos',
    comments: 'Comentarios',
    brief: 'Déjanos tus comentarios sobre el servicio que te brindamos durante tu estadía.',
    name: 'Su nombre',
    date: 'Fecha de hospedaje',
    hotel: 'Hotel donde se hospedó',
    comment: 'Sus comentarios y/o sugerencias',
    send: 'Enviar',
    commentSent: 'Comentarios enviados!',
  },
  loadingMenu: 'Cargando menú...',
  errorPdf: 'Ocurrió un error, recargue la página',
  and: 'y',
  acceptPrivacy: 'He leído, comprendo y acepto el ',
  acceptPrivacyPlural: 'He leído, comprendo y acepto los ',
  badEmail: 'Ingrese un correo válido',
  badPhone: 'Ingrese un número de teléfono válido',
  errorDates: 'Ingrese fechas válidas',
  errorPeople: 'Ingrese un número de personas correcta',
  steps: {
    select: 'Seleccionar hotel',
    dates: 'Fechas y habitaciones',
    personal: 'Datos personales',
    selectAnHotel: 'Selecciona uno de nuestros hoteles',
    selectRooms: 'Selecciona las habitaciones que necesites',
  },
  errorRooms: 'Error obteniendo habitaciones, por favor intente nuevo',
  errorAvailability: 'Error obteniendo disponibilidad, por favor intente nuevo',
  roomsReservation: {
    double: 'cama(s) matrimonial',
    individual: 'cama(s) individual',
    kingSize: 'cama(s) king size',
    queenSize: 'cama(s) queen size',
    adults: 'adultos',
    children: 'niños',
  },
  verifyAvailaility: 'Verificar disponibilidad',
  select: 'Seleccionar',
  offer: 'Oferta especial',
  notAvailable: 'No disponible',
  delete: 'Borrar',
  max: 'máximo',
  totalToPay: 'Total a pagar',
  taxesIncluded: 'Impuestos y cargos incluidos',
  roomLabel: 'habitaciones',
  nights: 'noches',
  generalInfo: 'Datos generales',
  paymentInfo: 'Datos de pago',
  nameOnCard: 'Nombre en la tarjeta',
  cardNumber: 'Número de tarjeta',
  cardNumberError: 'Tarjeta no válida',
  invalidField: 'No válido',
  thanksForBooking: '¡Gracias por su reservación!',
  jobOpportunities: 'Bolsa de trabajo'
};

export default spanishMessages;

/**
 * Footer
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import { Link } from 'react-router-dom';

import { Fab } from '@material-ui/core';

import { Container } from './styles';
import messages from '../../getMessages';

export default function Footer() {
  return (
    <Container>
      <div>
        <img className="logo" src="/logo-yellow.png" alt="Logo Hoteles Marioris amarillo" />
      </div>
      <div className="content">
        <div>
          <div className="header">{messages.hotels}</div>
          <Link to="/la-paz">Hotel Maioris La Paz</Link>
          <Link to="/guadalajara">Hotel Maioris Guadalajara</Link>
          <Link to="/kumate">Hotel Maioris Jesus Kumate Culiacán</Link>
          <Link to="/el-diez">Hotel Maioris El Diez Culiacán</Link>
          <Link to="/navolato">Hotel Maioris Navolato</Link>
          <Link to="/bellavista">Hotel Maioris Bellavista</Link>
        </div>
        <div className="divider" />
        <div>
          <div className="header">Legal</div>
          <Link to="/politicas">{messages.hotelPolitics}</Link>
          {true && <Link to="/aviso-privacidad">{messages.noticePrivacy}</Link>}
          {true && <Link to="/aviso-legal">{messages.noticeLegal}</Link>}
          {true && <Link to="/terminos-condiciones">{messages.termConditions}</Link>}
          {false && <Link to="/aviso-privacidad-hoteles-maioris.pdf" target="_blank">{messages.noticePrivacy}</Link>}
          {false && <Link to="">{messages.termAndConditions}</Link>}
        </div>
        <div className="divider" />
        <div>
          <div className="header">{messages.contact}</div>
          <a href="mailto:reservaciones@hotelesmaioris.com">reservaciones@hotelesmaioris.com</a>
          <div className="header">{messages.jobOpportunities}</div>
          <a href="mailto:recursoshumanos@hotelesmaioris.com">recursoshumanos@hotelesmaioris.com</a>
          {false && <a href="tel:6673982093">667 398 2093</a>}
          <div className="header">{messages.followUs}</div>
          <div className="social-media">
            <a
              href="https://www.facebook.com/HotelesMaioris"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/facebook.svg" alt="" />
            </a>
            <a
              href="https://instagram.com/hotelesmaioris?igshid=du9lhw16wx22"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/instagram.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
}
